import React, { useState, useRef, useEffect, useMemo } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { usePopper } from 'react-popper';

function InfoTooltip({ msg, children, iconClassName, size }) {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'right',
  });

  let displaySize;
  switch (size) {
    case 'xs':
      displaySize = 'w-[225px]';
      break;
    case 'sm':
      displaySize = 'w-[250px]';
      break;
    case 'md':
      displaySize = 'w-[350px]';
      break;
    case 'auto':
      displaySize = 'w-max';
      break;
    default:
      displaySize = 'w-[250px] md:w-[300px] lg:w-[400px] xl:w-[500px]';
      break;
  }

  return (
    <div className="relative group">
      <div ref={setTargetElement} className="">
        <InformationCircleIcon
          className={`${iconClassName ? iconClassName : 'ml-1 h-5 w-5 text-thaleria-orange-700'}`}
          onMouseEnter={() => {
            setPopperElement(popperElRef.current);
          }}
          onMouseLeave={() => setPopperElement(null)}
        />
      </div>
      <div
        ref={popperElRef}
        style={styles.popper}
        {...attributes.popper}
        className={`z-50 hidden group-hover:block absolute shadow-lg text-sm p-2 bg-gray-400 text-white h-auto ${displaySize} rounded-md`}
      >
        {msg}
        {children}
      </div>
    </div>
  );
}

export default InfoTooltip;
