import React, { useState, useMemo } from 'react';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import { ExternalLinkIcon, TrashIcon } from '@heroicons/react/outline';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import InputSimple from '../../../components/Inputs/InputSimple';
import allocationTypes from '../../../helpers/enum/allocationTypes';
import allocationTypesToString from '../../../helpers/enum/allocationTypesToString';
import { format, addDays, isAfter, isSameDay } from 'date-fns';
import { PlusCircleIcon } from '@heroicons/react/solid';
import formatCurrency from '../../../utils/formatCurrency';
import PopOutMenu from '../../../components/Menus/PopOutMenu';
import InputDateRange from '../../../components/Inputs/InputDate/InputDateRange';
import formatNumber from '../../../utils/formatNumber';
import billingUnits from '../../../helpers/enum/billingUnits';
function CreateInvoicingLineForm({
  newInvoicingLine,
  selectedPOBillingUnits,
  userOptions,
  timesheetOptions,
  purchaseOrderOptions,
  saveCustomLine,
  allocationsToAdd = [],
  setAllocationsToAdd,
  removeEntry,
  timesheetStart,
  timesheetEnd,
  timesheetEntries,
  onChangeUserId,
  onChangeTimesheetId,
  onChangeStaffOrderId,
}) {
  const [newAllocation, setNewAllocation] = useState({
    from_date: null,
    to_date: null,
    hours: null,
    type: allocationTypes.billable,
  });

  let allocationTypesListBoxData = [
    {
      value: allocationTypes.billable,
      label: allocationTypesToString[allocationTypes.billable],
    },
    {
      value: allocationTypes.onboarding,
      label: allocationTypesToString[allocationTypes.onboarding],
    },
    {
      value: allocationTypes.offboarding,
      label: allocationTypesToString[allocationTypes.offboarding],
    },
  ];

  const totalDaysWorked = useMemo(() => {
    let _totalHoursWorked = 0;
    timesheetEntries.forEach(tse => {
      _totalHoursWorked += Number(tse.adjusted_hours) || Number(tse.hours);
    });
    return formatNumber(_totalHoursWorked / 8, true, 3);
  }, [timesheetEntries]);

  const dateRangeLoop = async (from_date, to_date) => {
    // Parse the input strings to Date objects
    const fromDateObj = new Date(from_date);
    const toDateObj = new Date(to_date);

    // Initialize the loop variable
    let currentDate = fromDateObj;

    let selectedAllocations = [];

    // Loop while the current date is before or equal to the to_date
    while (isAfter(toDateObj, currentDate) || isSameDay(currentDate, toDateObj)) {
      // Perform actions with the current date (e.g., print or process)
      if (!allocationsToAdd.some(a => isSameDay(a.date, currentDate))) {
        selectedAllocations.push({
          date: currentDate,
          hours: newAllocation.hours,
          type: newAllocation.type,
        });
      }

      // Increment the current date by one day
      currentDate = addDays(currentDate, 1);
    }
    // Sort the array by the date property
    const sortedAllocations = [...allocationsToAdd, ...selectedAllocations].sort((a, b) => a.date - b.date);

    // Update the state with the sorted array
    return setAllocationsToAdd(sortedAllocations);
  };

  const addAllocation = async () => {
    if (!newAllocation.to_date) {
      setAllocationsToAdd(prev => [
        ...prev,
        {
          date: newAllocation.from_date,
          hours: newAllocation.hours,
          type: newAllocation.type,
        },
      ]);
      setNewAllocation({
        from_date: null,
        to_date: null,
        hours: null,
        type: allocationTypes.billable,
      });
    } else {
      await dateRangeLoop(newAllocation.from_date, newAllocation.to_date);
      setNewAllocation({
        from_date: null,
        to_date: null,
        hours: null,
        type: allocationTypes.billable,
      });
    }
  };

  const createHandler = (newInvoicingLine, allocationsToAdd) => {
    saveCustomLine(newInvoicingLine, allocationsToAdd);
  };

  const removeAllAllocations = () => {
    setAllocationsToAdd([]);
  };

  const canCreate = useMemo(() => {
    let _canCreate = false;
    if (
      allocationsToAdd.length &&
      newInvoicingLine?.user_id &&
      newInvoicingLine?.timesheet_id &&
      newInvoicingLine?.staff_order_id
    )
      _canCreate = true;
    return _canCreate;
  }, [allocationsToAdd, newInvoicingLine]);

  const addAllocationsTable = (
    <div>
      <table className="w-full table-auto">
        <tr>
          <th className="text-left pr-2 md:pr-4 py-4 w-36">Date</th>
          <th className="text-left px-2 md:px-4 py-4 w-32">Hours</th>
          <th className="text-left px-2 md:px-4 py-4">Type</th>
          {allocationsToAdd.length ? (
            <th
              className="text-sm text-blue-400 hover:text-blue-500 hover:underline font-normal text-center px-2 md:px-4 py-4 cursor-pointer"
              onClick={() => removeAllAllocations()}
            >
              Clear all
            </th>
          ) : (
            <th className="text-left pl-2 md:pl-4 py-4"></th>
          )}
        </tr>
        {allocationsToAdd.length
          ? allocationsToAdd.map((entry, index) => (
              <tr key={entry.uuid}>
                <td className="pl-2 md:pl-4 py-2 text-sm text-gray-500 w-36">{format(entry.date, 'dd/MM/yyyy')}</td>
                <td className="px-2 md:px-4 py-2 text-sm text-gray-500 w-32">{entry.hours}</td>
                <td className="px-2 md:px-4 py-2 text-sm text-gray-500">{allocationTypesToString[entry.type]}</td>
                <td className="flex justify-center pr-2 md:pr-4">
                  <TrashIcon
                    className="h-5 w-5 mt-2 cursor-pointer"
                    style={{ color: 'gray' }}
                    onClick={() => removeEntry(index)}
                  />
                </td>
              </tr>
            ))
          : ''}
        <tr>
          <td className="pr-2 md:pr-4 py-2 w-[220px]">
            <InputDateRange
              startDate={newAllocation.from_date}
              endDate={newAllocation.to_date}
              onChange={val => setNewAllocation(prev => ({ ...prev, from_date: val[0], to_date: val[1] }))}
              minDate={timesheetStart}
              maxDate={timesheetEnd}
              disabledDates={allocationsToAdd.map(alloc => new Date(alloc.date))}
              disabled={!newInvoicingLine.staff_order_id}
            />
          </td>
          <td className="px-2 md:px-4 py-2 w-32">
            <InputSimple
              value={newAllocation.hours}
              onChange={e => setNewAllocation(prev => ({ ...prev, hours: e.target.value ? Math.abs(e.target.value) : '' }))}
              type="number"
              min={1}
              max={16}
              oninput="this.value = Math.abs(this.value)"
              disabled={!newInvoicingLine.staff_order_id}
            />
          </td>
          <td className="px-2 md:px-4 py-2 w-36">
            <ReactSelect
              selectedOptionsIds={[newAllocation.type]}
              options={allocationTypesListBoxData}
              onChange={e => setNewAllocation(prev => ({ ...prev, type: e.value }))}
              disabled={true}
            />
          </td>
          <td className="h-full">
            {newAllocation.from_date && newAllocation.hours && newAllocation.type ? (
              <div className="w-full flex justify-center">
                <PlusCircleIcon onClick={addAllocation} className="h-6 w-6 text-indigo-700 cursor-pointer" />
              </div>
            ) : (
              <div className="w-full flex justify-center">
                <PlusCircleIcon className="h-6 w-6 text-gray-400" />
              </div>
            )}
          </td>
        </tr>
      </table>
    </div>
  );

  return (
    <div className="flex flex-col gap-y-6">
      <TwoColumnForm label="Select purchase order" description="Select user, period, purchase order">
        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-2">
            <ReactSelect
              label="User"
              selectedOptionsIds={[newInvoicingLine?.user_id]}
              options={userOptions}
              onChange={e => onChangeUserId(e.value)}
              isSearchable
            />
          </div>

          <div className="col-span-2">
            <ReactSelect
              className="col-span-2"
              label="Timesheet period"
              disabled={!newInvoicingLine.user_id}
              selectedOptionsIds={[newInvoicingLine?.timesheet_id]}
              options={timesheetOptions}
              onChange={e => onChangeTimesheetId(e.value)}
              orderOptions={false}
              isSearchable
            />
          </div>
          <div className="col-span-2">
            <ReactSelect
              className="col-span-2"
              label="Purchase order"
              selectedOptionsIds={[newInvoicingLine?.staff_order_id]}
              options={purchaseOrderOptions}
              disabled={!newInvoicingLine.timesheet_id}
              onChange={e => onChangeStaffOrderId(e.value)}
              isSearchable
            />
          </div>
        </div>
      </TwoColumnForm>
      <TwoColumnForm
        label="Add allocations"
        description="Select user, period, purchase order"
        onClick2={() => createHandler(newInvoicingLine, allocationsToAdd)}
        disableButton2={!canCreate}
        button2
        buttonText2="Create"
      >
        <div className="grid grid-cols-2 gap-3">
          <div className="flex justify-end col-span-2 w-full">
            {newInvoicingLine.staff_order_id ? (
              <PopOutMenu
                key={'allocations'}
                //Title needs to contain the PO order_ref but not the client and SO ref
                title={
                  <div className="flex flex-col gap-y-1">
                    <span>
                      Allocations for{' '}
                      {purchaseOrderOptions
                        .find(po => po.value === newInvoicingLine.staff_order_id)
                        .label.split('(')[0]
                        .trim()}
                    </span>
                    <div className="flex space-x-2 items-center align-middle">
                      <span className="font-normal text-md">{totalDaysWorked} days</span>
                      <ExternalLinkIcon
                        className="h-5 w-5 text-thaleria-orange-700  hover:text-thaleria-orange-500 cursor-pointer"
                        onClick={() => window.open(`/admin-panel/hr/timesheets/${newInvoicingLine.timesheet_id}`)}
                      />
                    </div>
                  </div>
                }
                openButton={
                  <p className="inline-flex text-sm underline decoration-dotted w-full justify-center text-thaleria-orange-400 hover:text-thaleria-orange-700 cursor-pointer text-center">
                    View existing allocations
                  </p>
                }
              >
                <ul className="divide-y divide-gray-200 pr-4 h-80 overflow-y-auto">
                  {timesheetEntries.length ? (
                    timesheetEntries
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map(entry => {
                        const hasAdjustment = entry.adjusted_hours || entry.adjusted_hours === 0;
                        return (
                          <li className="py-2 flex items-center w-full justify-between">
                            <span>
                              <p className="font-medium text-sm text-gray-400">{format(new Date(entry.date), 'EEEE do')}</p>
                            </span>
                            <p className="px-2 py-1 inline-flex text-sm leading-5 text-gray-600">
                              {hasAdjustment ? formatNumber(entry.adjusted_hours, true) : formatNumber(entry.hours, true)} hours
                              {hasAdjustment ? '*' : ''}
                            </p>
                          </li>
                        );
                      })
                  ) : (
                    <p className="text-sm">No allocations for this purchase order</p>
                  )}
                </ul>
              </PopOutMenu>
            ) : (
              <p className="inline-flex text-sm w-full justify-end text-gray-400 text-center py-1">View existing allocations</p>
            )}
          </div>
          <div className="col-span-2">{addAllocationsTable}</div>
          <span className="col-span-2 pt-4 pb-2 font-semibold">Summary</span>
          <SimpleEntry
            className="col-span-1"
            label={
              selectedPOBillingUnits.purchaseOrder === billingUnits.numbers.hours
                ? 'Staff order hours worked'
                : 'Staff order days worked'
            }
            data={
              selectedPOBillingUnits.purchaseOrder === billingUnits.numbers.hours
                ? newInvoicingLine.hours_worked
                : newInvoicingLine.days_worked
            }
          />
          <SimpleEntry
            className="col-span-1"
            label={
              selectedPOBillingUnits.salesOrder === billingUnits.numbers.hours
                ? 'Sales order hours worked'
                : 'Sales order days worked'
            }
            data={
              selectedPOBillingUnits.salesOrder === billingUnits.numbers.hours
                ? newInvoicingLine.hours_worked
                : newInvoicingLine.days_worked
            }
          />
          <SimpleEntry
            className="col-span-1"
            label="Staff order daily rate"
            data={
              newInvoicingLine.staff_order_daily_rate
                ? selectedPOBillingUnits.purchaseOrder === billingUnits.numbers.hours
                  ? formatCurrency(formatNumber(Number(newInvoicingLine.staff_order_daily_rate / 8), false, 2)) + ' /hour'
                  : formatCurrency(newInvoicingLine.staff_order_daily_rate) + ' /day'
                : '-'
            }
          />
          <SimpleEntry
            className="col-span-1"
            label="Sales order daily rate"
            data={
              newInvoicingLine.sales_order_daily_rate
                ? selectedPOBillingUnits.salesOrder === billingUnits.numbers.hours
                  ? formatCurrency(formatNumber(Number(newInvoicingLine.sales_order_daily_rate / 8), false, 2)) + ' /hour'
                  : formatCurrency(newInvoicingLine.sales_order_daily_rate) + ' /day'
                : '-'
            }
          />
          <SimpleEntry className="col-span-1" label="Cost" data={formatCurrency(newInvoicingLine.cost)} />
          <SimpleEntry className="col-span-1" label="Revenue" data={formatCurrency(newInvoicingLine.revenue)} />
          <SimpleEntry className="col-span-1" label="Vat recovered" data={formatCurrency(newInvoicingLine.vat_recovered)} />
          <SimpleEntry className="col-span-1" label="Vat owed" data={formatCurrency(newInvoicingLine.vat_owed)} />
        </div>
      </TwoColumnForm>
    </div>
  );
}

export default CreateInvoicingLineForm;
