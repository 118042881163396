import * as React from 'react';
import { lineItemTypesV2 } from '../../helpers/enum/lineItemTypes';
import formatNumber from '../../utils/formatNumber';
import formatCurrency from '../../utils/formatCurrency';
import InfoTooltip from '../Tooltips/InfoTooltip';
import { ExternalLinkIcon } from '@heroicons/react/outline';

const AccountsPayableLineItemDetails = ({ lineItems, totals }) => {
  console.log(lineItems);
  return (
    <>
      <div className={`.table-wrapper col-span-2 px-2 border border-gray-300 rounded-md overflow-auto`}>
        <table className="min-w-[1000px] table-auto h-max mt-2 px-4 ap-table">
          <thead className="w-full">
            <tr className="text-md ">
              <th className="ap-table-head-th text-left">Description</th>
              <th className="ap-table-head-th text-left">Type</th>
              <th className="ap-table-head-th text-left">Units</th>
              <th className="ap-table-head-th text-left">UoM</th>
              <th className="ap-table-head-th text-left">Price</th>
              <th className="ap-table-head-th text-left">VAT rate</th>
              <th className="ap-table-head-th text-left">Total Excl VAT</th>
              <th className="ap-table-head-th text-left">VAT</th>
              <th className="ap-table-head-th text-left">Total Incl VAT</th>
            </tr>
          </thead>
          <tbody className="p-2 h-max w-full text-sm">
            {lineItems?.length ? (
              lineItems.map((li, i) => {
                return (
                  <tr className="border-dashed my-1 h-8" key={li?.id || li?.uuid}>
                    <td className="text-left">
                      <div className="flex items-center justify-start">
                        {li.description}
                        <InfoTooltip className="flex flex-col gap-y-1" size="sm">
                          <span className="flex items-center">
                            Timesheet #{li.timesheet_id}
                            <ExternalLinkIcon
                              className="ml-2 h-5 w-5 cursor-pointer text-thaleria-orange-500 hover:text-thaleria-orange-300"
                              onClick={() => window.open(`/admin-panel/hr/timesheets/${li.timesheet_id}`)}
                            />
                          </span>
                          <span>Purchase order: {li.staffOrder?.order_ref}</span>
                        </InfoTooltip>
                      </div>
                    </td>
                    <td className="ap-table-body-td text-left">{lineItemTypesV2.accountsPayable.strings[li.type]}</td>
                    <td className="ap-table-body-td text-left">{formatNumber(li.units, true, 6)}</td>
                    <td className="ap-table-body-td text-left">{li.uom}</td>
                    <td className="ap-table-body-td text-left">{formatCurrency(li.price)}</td>
                    <td className="ap-table-body-td text-left">{formatNumber(li.vat_rate, true) + '%'}</td>
                    <td className="ap-table-body-td text-left">{formatCurrency(li.total)}</td>
                    <td className="ap-table-body-td text-left">{formatCurrency(li.vat)}</td>
                    <td className="ap-table-body-td text-left relative">{formatCurrency(li.total_and_vat)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={9} className="text-left py-1">
                  No line items
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col gap-y-2 mt-4">
        <span className="font-semibold text-lg my-2">Summary</span>
        <span>
          <span className="font-semibold">Total:</span> {formatCurrency(totals?.total)}
        </span>
        <span>
          <span className="font-semibold">VAT:</span> {formatCurrency(totals?.vat)}
        </span>
        <span>
          <span className="font-semibold">Grand total:</span> {formatCurrency(totals?.total_and_vat)}
        </span>
      </div>
    </>
  );
};

export default AccountsPayableLineItemDetails;
