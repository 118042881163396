import React, { useEffect, useMemo, useRef, useState } from 'react';
import Table from '../../../../containers/ServerSideTable';
import useApi from '../../../../hooks/useApi';
import PreviewBox from '../../../../containers/PDFViewer/PreviewBox';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import ClipLoader from 'react-spinners/ClipLoader';
import TableTabs from '../../../../components/TableItems/TableTabs';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import MonthRangeColumnFilter from '../../../../components/TableItems/MonthRangeColumnFilter';
import NumberRangeColumnFilter from '../../../../components/TableItems/NumberRangeColumnFilter';
import formatCurrency from '../../../../utils/formatCurrency';
import { TimeSheetStatusLabels } from '../../../../helpers/enum/timeSheet';
import months from '../../../../helpers/enum/months';
import AdminSidebar from '../../AdminSidebar';
import CostTableForPreview from '../../../../containers/Timesheets/CostTableForInvoicePreview';
import { Link } from 'react-router-dom';
import { ClipboardCheckIcon } from '@heroicons/react/outline';
import { downloadBase64File } from '../../../../helpers';
import UnpaidKPIModal from '../../../../containers/Finance/AccountsPayable/UnpaidKPIModal';
import paidStatus from '../../../../helpers/enum/paidStatus';
import PayInvoice from '../../../../components/Modals/PayInvoice';
import { useHistory } from 'react-router-dom';
import { lineItemTypesV2 } from '../../../../helpers/enum/lineItemTypes';
import { showSuccessNotification } from '../../../../store/app/actions';
import { useDispatch } from 'react-redux';
import format from 'date-fns/format';
import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
import { americanDate, date } from '../../../../helpers/date';
import SimpleAlert from '../../../../components/Modals/SimpleAlert';

function AccountsPayable() {
  const [accountsPayableData, setAccountsPayableData] = useState();
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [previewFile, setPreviewFile] = useState(false);
  const [filterOptions, setFilterOptions] = useState();
  const [activeTab, setActiveTab] = useState('Unpaid');
  const [showBulkDownloadAlert, setShowBulkDownloadAlert] = useState(false);
  const [bulkDownloading, setBulkDownloading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [showKPI, setShowKPI] = useState(false);
  const [filters, setFilters] = useState(false);
  const [invoiceToPay, setInvoiceToPay] = useState();
  const [showPayModal, setShowPayModal] = useState(false);
  const [showAutoApproveModal, setShowAutoApproveModal] = useState(false);
  const {
    accountsPayable: {
      getAccountsPayable,
      getAccountsPayableOptions,
      updatePaidStatus,
      bulkDownloadAP,
      previewAPFile,
      downloadAPFile,
      updateApprovedStatus,
      autoApproveAPs,
    },
  } = useApi();

  const history = useHistory();
  const dispatch = useDispatch();

  const myRefs = useRef({});

  useEffect(() => {
    getAccountsPayableOptions(activeTab === 'Paid' ? true : false, activeTab === 'Unapproved' ? false : true).then(r => {
      setFilterOptions(r);
    });
  }, [activeTab]);

  const fetchAccountsPayable = async (
    page = 0,
    filters = [],
    sortByArray = [],
    globalFilter,
    downloadToCsv,
    source,
    bulkDownload,
  ) => {
    const paid = activeTab === 'Paid' ? true : false;
    const approved = activeTab === 'Unapproved' ? false : true;
    const users = filters.find(filter => filter.id === 'user')?.value;
    const due_date = filters.find(filter => filter.id === 'due_date')?.value;
    const cost = filters.find(filter => filter.id === 'cost')?.value;
    const vat = filters.find(filter => filter.id === 'vat_recovered')?.value;
    const paidAmount = filters.find(filter => filter.id === 'paid_amount')?.value;
    const costAndVat = filters.find(filter => filter.id === 'cost_and_vat')?.value;
    const iban = filters.find(filter => filter.id === 'iban')?.value;
    const bicSwift = filters.find(filter => filter.id === 'bic_swift')?.value;
    const companyName = filters.find(filter => filter.id === 'company_name')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;
    let csvData = [];

    setFilters({
      users,
      due_date,
      cost,
      vat,
      costAndVat,
      iban,
      bicSwift,
      companyName,
      sortBy,
      globalFilter,
      paidAmount,
    });

    let dueDate;
    if (due_date !== undefined) {
      dueDate = due_date.map(d => {
        if (d !== undefined) return americanDate(d);
      });
    }

    //FIXME: Are we always doing multiple fetches on all tables depending on these actions? seems that at least 2 fetches go to backend when we perform one of these actions
    //FIXME: Cant we separate the data fetch ( data = await getAP(...) ) and then put the if statements to downloadtocsv/bulkdownload directly on the received data object?

    if (downloadToCsv) {
      await getAccountsPayable(
        page + 1,
        paid,
        users,
        dueDate,
        cost,
        vat,
        costAndVat,
        approved,
        paidAmount,
        iban,
        bicSwift,
        companyName,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
        bulkDownload,
      ).then(r => {
        r.forEach(e => {
          const due_date = new Date(e?.due_date);
          csvData.push({
            id: e.id,
            user: e.user.full_name,
            due_date: months[due_date.getMonth()] + ' - ' + due_date.getFullYear(),
            cost: e?.cost + (e?.has_custom_lines ? '*' : ''),
            cost_and_vat: e?.cost_and_vat + (e?.has_custom_lines ? '*' : ''),
            vat_recovered: e?.vat + (e?.has_custom_lines ? '*' : ''),
            paid_amount: e?.paid_amount ? e.paid_amount : '-',
            paid:
              e?.paid === paidStatus.numbers.paid
                ? 'Paid'
                : e?.paid === paidStatus.numbers.partiallyPaid
                ? 'Partially paid'
                : 'Not paid',
            iban: e?.iban ? `${e?.iban}${e?.iban_change ? ' (new)' : ''}` : '-',
            bic_swift: e?.bic_swift || '-',
            company_name: e?.company_name || '-',
          });
        });
      });
      return csvData;
    }

    if (bulkDownload) {
      getAccountsPayable(
        page + 1,
        paid,
        users,
        dueDate,
        cost,
        vat,
        costAndVat,
        approved,
        paidAmount,
        iban,
        bicSwift,
        companyName,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
        bulkDownload,
      ).then(r => {
        setBulkDownloading(true);
        bulkDownloadAP(
          r.map(el => el.id),
          source,
        )
          .then(res => {
            setBulkDownloading(false);
            setShowBulkDownloadAlert(false);
          })
          .catch(err => {
            setBulkDownloading(false);
          });
      });
      return;
    }

    getAccountsPayable(
      page + 1,
      paid,
      users,
      dueDate,
      cost,
      vat,
      costAndVat,
      approved,
      paidAmount,
      iban,
      bicSwift,
      companyName,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
      bulkDownload,
    ).then(r => {
      setAccountsPayableData(r);
    });
  };

  const tableData = useMemo(() => {
    let array = [];
    if (accountsPayableData?.rows?.length) {
      accountsPayableData.rows.forEach(e => {
        const due_date = new Date(e?.due_date);
        array.push({
          id: {
            id: e?.id,
            full_name: e?.user.full_name,
            comments: e?.comments,
            invoice_number: e?.invoice_number,
          },
          user: e?.user?.full_name,
          due_date: due_date,
          cost: formatCurrency(e?.cost) + (e?.has_custom_lines ? '*' : ''),
          cost_and_vat: formatCurrency(e?.cost_and_vat) + (e?.has_custom_lines ? '*' : ''),
          vat_recovered: formatCurrency(e?.vat) + (e?.has_custom_lines ? '*' : ''),
          paid: {
            id: e?.id,
            paid: e?.paid,
            approved: e?.approved,
            paid_amount: e?.paid_amount,
            payment_date: e?.payment_date,
            cost: e?.cost_and_vat,
          },
          paid_amount: e?.paid_amount ? formatCurrency(e.paid_amount) : '-',
          iban: e?.iban ? `${e?.iban}${e?.iban_change ? ' (new)' : ''}` : '-',
          bic_swift: e?.bic_swift || '-',
          company_name: e?.company_name || '-',
          amount_match: e?.amount_match,
          approved: {
            id: e?.id,
            approved: e?.approved,
            canBeApproved:
              e?.paid !== paidStatus.numbers.unpaid ||
              e?.lineItems.find(li => li.type === lineItemTypesV2.accountsPayable.numbers.unapprovedProjectAllocations)
                ? {
                    bool: false,
                    reason:
                      e?.paid !== paidStatus.numbers.unpaid
                        ? 'Invoice had been partially or fully paid.'
                        : e?.lineItems.find(
                            li => li.type === lineItemTypesV2.accountsPayable.numbers.unapprovedProjectAllocations,
                          ) && "Timesheet(s) must be approved before approving the user's invoice.",
                  }
                : { bool: true },
          },
        });
      });
    }
    return array;
  }, [accountsPayableData]);

  const onSaveLine = data => {
    //this function is only used in PreviewBox to approve/unapprove AP
    updateApprovedStatus(data.id, { approved: data.approved })
      .then(res => {
        dispatch(showSuccessNotification(data.approved ? "Approved user's invoice" : "Unapproved user's invoice"));
        setAccountsPayableData(prev => ({ ...prev, rows: accountsPayableData.rows.filter(ap => ap.id !== data.id) }));
        setPreviewFile(false);
      })
      .catch(err => {
        setPreviewFile(false);
        throw err;
      });
  };

  const previewHandler = async (id, fullName, e, invoiceNumber) => {
    e.stopPropagation();
    setLoadingPreview(id);
    previewAPFile(id)
      .then(res => {
        if (res.data.fileType.toLowerCase() !== 'pdf') {
          setLoadingPreview(false);
          return alert("Cannot preview this file, please download it directly from the user's invoice details page.");
        }
        setPreviewFile({
          title: invoiceNumber ? `${fullName}'s invoice #${invoiceNumber}` : `${fullName}'s invoice`,
          id: id,
          path: res.data.result.data,
          type: res.data.fileType,
          underTitle: <CostTableForPreview id={id} onSaveLine={onSaveLine} />,
          fileName: res.data?.fileName,
        });
        setLoadingPreview(false);
      })
      .catch(err => {
        setLoadingPreview(false);
      });
  };

  const updateInvoicePaid = async (id, invoicePaid, date, paidAmount) => {
    let dataToUpdate = {
      paid: invoicePaid,
      payment_date: invoicePaid === paidStatus.numbers.unpaid ? null : date,
      paid_amount: invoicePaid === paidStatus.numbers.unpaid ? null : paidAmount,
    };
    updatePaidStatus(id, dataToUpdate).then(res => {
      if (
        (invoicePaid === paidStatus.numbers.paid && activeTab === 'Unpaid') ||
        (invoicePaid !== paidStatus.numbers.paid && activeTab === 'Paid')
      ) {
        const newRows = accountsPayableData.rows.filter(r => r.id != res?.id);
        setAccountsPayableData(prev => ({
          ...prev,
          rows: newRows,
        }));
      } else {
        const newRows = accountsPayableData.rows.map(r => {
          let newObj = r;
          if (r.id === id) {
            newObj.paid = res.paid;
            newObj.paid_amount = res.paid_amount;
            newObj.payment_date = res.payment_date;
          }
          return newObj;
        });
        if (invoicePaid === paidStatus.numbers.partiallyPaid) myRefs.current[id].indeterminate = true;
        else myRefs.current[id].indeterminate = false;

        setAccountsPayableData(prev => ({
          ...prev,
          rows: newRows,
        }));
      }
      setShowPayModal(false);
    });
  };

  const onPaidClickHandler = invoice => {
    setInvoiceToPay({
      id: invoice.id,
      paid: invoice.paid,
      paid_amount: invoice.paid_amount,
      payment_date: invoice.payment_date,
      cost: invoice.cost,
    });
    setShowPayModal(true);
  };

  const onApprovedClickHandler = value => {
    updateApprovedStatus(value.id, { approved: !value.approved })
      .then(res => {
        dispatch(
          showSuccessNotification(`${!value?.approved ? "Approved user's invoice" : "Cancelled approval of user's invoice."}`),
        );
        setAccountsPayableData(prev => ({ ...prev, rows: accountsPayableData.rows.filter(ap => ap.id !== value.id) }));
      })
      .catch(err => {
        throw err;
      });
  };

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="flex justify-center gap-x-2 items-center">
              <span>{value.id}</span>
              <div className="w-5 h-5 translate-y-0.5 mr-2 mb-1">
                {loadingPreview === value?.id ? (
                  <ClipLoader size={18} color={'#4f46e5'} />
                ) : (
                  <EyeIcon
                    className="w-5 h-5 cursor-pointer"
                    onClick={e => previewHandler(value?.id, value?.full_name, e, value?.invoice_number)}
                  />
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'User',
        accessor: 'user',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions ? filterOptions?.userOptions : [],
      },
      {
        Header: 'Due date',
        accessor: 'due_date',
        Filter: DateRangeColumnFilter,
        filter: 'date',
        Cell: ({ value }) => {
          if (value) return format(value, 'dd/MM/yy');
          if (!value) return null;
        },
      },
      {
        Header: 'Cost',
        accessor: 'cost',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'VAT recovered',
        accessor: 'vat_recovered',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Total',
        accessor: 'cost_and_vat',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Match',
        accessor: 'amount_match',
        Filter: SelectColumnFilter,
        filterOptions: [
          {
            value: true,
            label: 'Yes',
          },
          {
            value: false,
            label: 'No',
          },
        ],
        Cell: ({ value }) => {
          return (
            <span className="">
              <p className={`${!value ? 'bg-thaleria-orange-300 rounded-md py-1 px-2 w-min' : 'px-2'}`}>{value ? 'Yes' : 'No'}</p>
            </span>
          );
        },
      },
    ];

    if (activeTab === 'Unpaid' || activeTab === 'Unapproved') {
      cols.push({
        Header: 'Approved',
        accessor: 'approved',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <span className="flex justify-center items-center relative group">
              <input
                id={value.id}
                aria-describedby="approved-description"
                name="approved"
                type="checkbox"
                disabled={!value.canBeApproved?.bool}
                checked={value?.approved}
                onChange={() => onApprovedClickHandler(value)}
                onClick={e => e.stopPropagation()}
                className={`focus:ring-thaleria-orange-500 h-4 w-4 border-gray-300 rounded mb-1 ${
                  !value.canBeApproved?.bool ? 'bg-gray-200 cursor-not-allowed text-gray-300' : 'text-thaleria-orange-600'
                }`}
              />
              {/* Tooltip (Shown on hover only if canBeApproved is false) */}
              {!value.canBeApproved?.bool && (
                <div
                  className="hidden group-hover:block absolute z-50 shadow-lg text-sm p-2 bg-gray-400 text-white h-auto w-[480px] rounded-md left-100"
                  style={{ left: '-470px' }}
                >
                  <p>{value?.canBeApproved?.reason}</p>
                </div>
              )}
            </span>
          );
        },
      });
    }

    if (activeTab === 'Paid' || activeTab === 'Unpaid') {
      cols.push({
        Header: 'Paid',
        accessor: 'paid',
        disableFilters: true,
        disableSortBy: activeTab === 'Unpaid' ? false : true,
        Cell: ({ value }) => {
          return (
            <span className="flex justify-center divide-x-2 items-center">
              <input
                id={value.id}
                ref={el => {
                  if (value.paid === paidStatus.numbers.partiallyPaid && el?.indeterminate === false) {
                    el.indeterminate = true;
                  }

                  return (myRefs.current[value.id] = el);
                }}
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                disabled={!value.approved}
                checked={value?.paid}
                onChange={() => onPaidClickHandler(value)}
                onClick={e => e.stopPropagation()}
                className={`focus:ring-thaleria-orange-500 h-4 w-4 border-gray-300 rounded mb-1 ${
                  !value.approved ? 'bg-gray-200 cursor-not-allowed text-gray-300' : 'text-thaleria-orange-600'
                }`}
              />
            </span>
          );
        },
      });
    }
    if (activeTab === 'Unpaid') {
      cols.push({
        Header: 'Paid amount',
        accessor: 'paid_amount',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      });
    }
    cols.push({
      Header: 'IBAN',
      accessor: 'iban',
    });

    cols.push({
      Header: 'BIC SWIFT',
      accessor: 'bic_swift',
    });
    cols.push({
      Header: 'Company name',
      accessor: 'company_name',
    });

    return cols;
  }, [accountsPayableData, loadingPreview]);

  const pages = [
    { name: 'Finance', href: '/admin-panel/finance/invoicing-items', current: false },
    { name: 'Accounts Payable', href: '/admin-panel/finance/accounts-payable', current: false },
  ];

  const tabs = [
    {
      name: 'Unpaid',
      onClick: () => activeTab !== 'Unpaid' && setActiveTab('Unpaid'),
    },
    {
      name: 'Paid',
      onClick: () => activeTab !== 'Paid' && setActiveTab('Paid'),
    },
    {
      name: 'Unapproved',
      onClick: () => activeTab !== 'Unapproved' && setActiveTab('Unapproved'),
    },
  ];

  const tableName =
    activeTab === 'Paid'
      ? 'paidAccountsPayable'
      : activeTab === 'Unpaid'
      ? 'unpaidAccountsPayable'
      : activeTab === 'Unapproved' && 'backlogAccountsPayable';

  const onDownloadClick = (id, fileName, type) => {
    setDownloading(true);
    downloadAPFile(id)
      .then(response => {
        downloadBase64File(response.data, `${fileName}.${type}`);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  const rowOnClick = row => {
    //in this table, id is an object so users can preview the invoice file. in this case we need to access id.id
    history.push(`/admin-panel/finance/accounts-payable/${row.original.id.id}`);
  };

  const addButton = {
    link: '/admin-panel/finance/accounts-payable/create',
    text: 'Create invoice',
  };

  const autoApproveOnClick = () => {
    setShowAutoApproveModal(true);
  };

  const handleAcceptAutoApprove = () => {
    autoApproveAPs().then(res => {
      window.location.reload();
    });
  };

  const autoApproveButton = {
    text: 'Auto approve APs',
    icon: <ClipboardCheckIcon className="h-5 w-5" />,
    onClick: autoApproveOnClick,
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <SimpleAlert
        errorTitle="Auto-approve APs"
        errorMsg={
          <div>
            <p className="">This will approve all account payables that respond to the following criteria:</p>
            <ul className="space-y-1 py-2 pl-4">
              <li>1) only include approved allocations</li>
              <li>2) don't have any custom lines added</li>
              <li>3) have the default due date of the 7th of N+1</li>
              <li>4) the user declared that the PDF amount matches the amount on our system.</li>
            </ul>
            <p>After running this script, you will be redirected to the Approved and Unpaid AP table. Do you wish to proceed?</p>
          </div>
        }
        onAcceptText="Proceed"
        onAcceptClick={handleAcceptAutoApprove}
        onDeclineText="Cancel"
        show={showAutoApproveModal}
        hide={() => setShowAutoApproveModal(false)}
      />
      <TableTabs tabs={tabs} />
      <UnpaidKPIModal show={showKPI} setShow={setShowKPI} filters={filters} />
      <PreviewBox
        filePath={previewFile.path}
        fileType={previewFile.type}
        title={previewFile.title}
        showPreview={previewFile ? true : false}
        handleHide={() => setPreviewFile(false)}
        underTitle={previewFile.underTitle}
        accountsPayableId={previewFile.id}
        fileName={previewFile.fileName}
        onDownloadClick={onDownloadClick}
        downloading={downloading}
      />
      <PayInvoice
        show={showPayModal}
        setShow={setShowPayModal}
        id={invoiceToPay?.id}
        _paid={invoiceToPay?.paid}
        _paidAmount={invoiceToPay?.paid_amount}
        _paymentDate={invoiceToPay?.payment_date}
        onConfirm={updateInvoicePaid}
        totalAmount={invoiceToPay?.cost}
      />
      <Table
        columns={columns}
        data={tableData}
        fetchData={fetchAccountsPayable}
        pageCount={accountsPayableData?.totalPages}
        tableName={tableName}
        totalItems={accountsPayableData?.totalItems}
        bulkDownloadButton={true}
        bulkDownloading={bulkDownloading}
        setBulkDownloading={setBulkDownloading}
        showBulkDownloadAlert={showBulkDownloadAlert}
        setShowBulkDownloadAlert={setShowBulkDownloadAlert}
        kpiButton
        setShowKPI={setShowKPI}
        rowOnClick={rowOnClick}
        addButton={addButton}
        additionalButtons={tableName === 'backlogAccountsPayable' ? [autoApproveButton] : null}
      />
    </AdminSidebar>
  );
}

export default AccountsPayable;
