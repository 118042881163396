import CreditNotePDFPreview from './CreditNotePDFPreview';
import React, { useEffect, useState } from 'react';
import InputSimple from '../../../components/Inputs/InputSimple';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import TextArea from '../../../components/Inputs/TextArea';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import { XCircleIcon } from '@heroicons/react/outline';
import { lineItemTypesV2 } from '../../../helpers/enum/lineItemTypes';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import { DotsCircleHorizontalIcon } from '@heroicons/react/outline';
import Error from '../../../components/Error/Error';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import PlusMedium from '../../../components/Icons/PlusMediumIcon';
import AddLineItemModal from './AddLineItemModal';
import Toggle from '../../../components/Toggles/Toggle';
import Checkbox from '../../../components/Checkbox/Checkbox';
import InputDateRange from '../../../components/Inputs/InputDate/InputDateRange';
import { format, isSameDay } from 'date-fns';
import formatCurrency from '../../../utils/formatCurrency';
import formatNumber from '../../../utils/formatNumber';
import * as countryID from '../../../helpers/enum/CountryIDs';
import vatDisclaimers from '../../../helpers/vatDisclaimers';
import clientTypes from '../../../helpers/enum/clientTypes';

function CreateCreditNoteForm({
  invoiceOptions,
  creditNoteData,
  setCreditNoteData,
  lineItems,
  setLineItems,
  formErrors,
  setFormErrors,
  clientOptions,
  handleCreateCreditNote,
  fetchInvoiceAndSetCreditNoteData,
  isLoading,
}) {
  const customCreditNote = creditNoteData.accounts_receivable_id === 'custom';
  const [showAddLineItemModal, setShowAddLineItemModal] = useState(false);
  const [selectedLineItemIndex, setSelectedLineItemIndex] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    //applies correct vat disclaimer depending on client
    if (creditNoteData.client_id && clientOptions.length) {
      const client = clientOptions.find(c => c.value === creditNoteData.client_id);

      if (client?.country_id !== countryID.belgium && client?.type === clientTypes.numbers.Private) {
        setCreditNoteData(prev => ({
          ...prev,
          metadata: {
            ...prev.metadata,
            vat_disclaimer: vatDisclaimers.default,
          },
        }));
      } else if (creditNoteData.metadata?.vat_disclaimer) {
        setCreditNoteData(prev => ({
          ...prev,
          metadata: {
            ...prev.metadata,
            vat_disclaimer: null,
          },
        }));
      }
    }
  }, [creditNoteData.client_id, clientOptions]);

  const handleLineItemChange = (property, value, lineItemIndex) => {
    const updatedLineItems = lineItems.map((l, i) => {
      if (property === 'dateRange') {
        let periodString = '';

        if (value[0]) periodString = periodString + `${format(value[0], 'dd/MM/yy')}`;
        if (value[1] && !isSameDay(value[0], value[1])) periodString = periodString + `${format(value[1], 'dd/MM/yy')}`;

        return i === lineItemIndex
          ? {
              ...l,
              ['from_date']: value[0],
              ['to_date']: value[1],
              ['metadata']: {
                ...l.metadata,
                period: periodString,
              },
            }
          : l;
      } else {
        return i === lineItemIndex ? { ...l, [property]: value } : l;
      }
    });
    return setLineItems(updatedLineItems);
  };

  const handleShowAddLineItemModal = index => {
    setSelectedLineItemIndex(index);
    setShowAddLineItemModal(true);
  };

  const handleSelectAccountReceivable = e => {
    let invoiceNb = e.label;
    fetchInvoiceAndSetCreditNoteData(invoiceNb);
  };

  const handleToggleReferences = (field, value) => {
    const updatedCreditNoteMetadata = {
      ...creditNoteData.metadata,
      showReferences: { ...creditNoteData.metadata.showReferences, [field]: value },
    };

    return setCreditNoteData(prev => ({ ...prev, metadata: updatedCreditNoteMetadata }));
  };

  const isAllocationsLine = type => {
    let isAllocationsLine = false;
    if (type === lineItemTypesV2.creditNotes.numbers.projectAllocations) isAllocationsLine = true;
    return isAllocationsLine;
  };

  return (
    <div className="flex-1 overflow-auto px-4 pb-4">
      <CreditNotePDFPreview
        setShowPreview={setShowPreview}
        showPreview={showPreview}
        creditNoteData={creditNoteData}
        lineItems={lineItems}
        canDownload={false}
      />
      <AddLineItemModal
        showAddLineItemModal={showAddLineItemModal}
        setShowAddLineItemModal={setShowAddLineItemModal}
        setLineItems={setLineItems}
        clientId={creditNoteData.client_id}
        selectedLineItemIndex={selectedLineItemIndex}
        setSelectedLineItemIndex={setSelectedLineItemIndex}
        lineItems={lineItems}
        customCreditNote={customCreditNote}
      />
      <div className="w-full flex justify-between py-4">
        <span className="text-lg text-gray-700">{'Create credit note'}</span>
        <ButtonPrimary text="Show preview" onClick={() => setShowPreview(true)} />
      </div>
      <form className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <ReactSelect
            label="Account receivable*"
            error={formErrors.find(e => e.field === 'accounts_receivable_id')?.msg}
            selectedOptionsIds={[creditNoteData.accounts_receivable_id]}
            onChange={e => {
              handleSelectAccountReceivable(e);
            }}
            options={invoiceOptions}
            isSearchable
            disabled={creditNoteData.accounts_receivable_id === 'custom'}
            orderOptions={false}
          />
        </div>
        <div className="col-span-1 flex items-end">
          <Toggle
            label="Custom credit note"
            className="mb-2"
            onChange={e => {
              handleSelectAccountReceivable(
                creditNoteData.accounts_receivable_id === 'custom' ? { label: null } : { label: 'custom' },
              );
            }}
            enable={creditNoteData.accounts_receivable_id === 'custom'}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <InputSimple
            label="Credit note number*"
            error={formErrors.find(e => e.field === 'credit_note_number')?.msg}
            value={creditNoteData.credit_note_number}
            onChange={e => setCreditNoteData(prev => ({ ...prev, credit_note_number: e.target.value }))}
            type="number"
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <InputDate
            label="Issue date*"
            selected={creditNoteData.issue_date}
            error={formErrors.find(e => e.field === 'issue_date')?.msg}
            onChange={date => setCreditNoteData(prev => ({ ...prev, issue_date: date }))}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <ReactSelect
            label="Client*"
            options={clientOptions}
            error={formErrors.find(e => e.field === 'client_id')?.msg}
            disabled={creditNoteData.accounts_receivable_id !== 'custom'}
            selectedOptionsIds={[creditNoteData.client_id]}
            onChange={e => {
              setCreditNoteData(prev => ({ ...prev, client_id: e.value, metadata: { ...prev.metadata, to: e.clientDetails } }));
            }}
            isSearchable
          />
        </div>
        <div className="col-span-2 md:col-span-1 flex">
          <InputSimple
            label="Subject*"
            error={formErrors.find(e => e.field === 'subject')?.msg}
            value={creditNoteData.metadata.subject}
            disabled={!creditNoteData.accounts_receivable_id}
            onChange={e => setCreditNoteData(prev => ({ ...prev, metadata: { ...prev.metadata, subject: e.target.value } }))}
          />
        </div>
        <div className="col-span-2">
          <TextArea
            rows={3}
            disabled={!creditNoteData.accounts_receivable_id}
            label="Header comment*"
            error={formErrors.find(e => e.field === 'header_comment')?.msg}
            onChange={e =>
              setCreditNoteData(prev => ({ ...prev, metadata: { ...prev.metadata, header_comment: e.target.value } }))
            }
            value={creditNoteData.metadata.header_comment}
          />
        </div>

        <div className="col-span-2">
          <div className="flex">
            <label className="block text-md font-medium text-gray-700 mr-2 mb-2">Line items*</label>
            {formErrors.find(e => e.field === 'lineItems') ? (
              <Error message={formErrors.find(e => e.field === 'lineItems')?.msg} />
            ) : (
              ''
            )}
          </div>
          {lineItems.length ? (
            <>
              <div className="w-full">
                <div className={`gap-x-2 mb-1 ${lineItems?.length ? 'flex justify-between' : 'hidden'}`}>
                  <label className="text-sm font-medium text-gray-700">Contract references</label>
                  <div className="flex space-x-4">
                    <Checkbox
                      className="w-[105px]"
                      horizontal
                      smallText
                      labelTitle
                      value={creditNoteData.metadata?.showReferences?.partnerContract}
                      onChange={e => handleToggleReferences('partnerContract', e.target.checked)}
                      title="Sales contract"
                    />
                    <Checkbox
                      className="w-[140px]"
                      horizontal
                      smallText
                      labelTitle
                      value={creditNoteData.metadata?.showReferences?.frameworkContract}
                      onChange={e => handleToggleReferences('frameworkContract', e.target.checked)}
                      title="Framework contract"
                    />
                    <Checkbox
                      className="w-[90px]"
                      horizontal
                      smallText
                      labelTitle
                      value={creditNoteData.metadata?.showReferences?.salesOrder}
                      onChange={e => handleToggleReferences('salesOrder', e.target.checked)}
                      title="Sales order"
                    />
                    <Checkbox
                      className="w-[120px]"
                      horizontal
                      smallText
                      labelTitle
                      value={creditNoteData.metadata?.showReferences?.specificContract}
                      onChange={e => handleToggleReferences('specificContract', e.target.checked)}
                      title="Specific contract"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mb-2">
                <div className="grid gap-1 grid-cols-9" style={{ gridTemplateColumns: 'repeat(16, minmax(0, 1fr))' }}>
                  <label className="block text-sm font-medium text-gray-700 col-span-5">Description</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-2">Type</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-2">Period</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-1">Quantity</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-2">UoM</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-2">Price</label>
                  <label className="block text-sm font-medium text-gray-700 col-span-2">VAT</label>
                </div>
                {lineItems.map((item, index) => {
                  return (
                    <div className="grid gap-1 group relative" style={{ gridTemplateColumns: 'repeat(16, minmax(0, 1fr))' }}>
                      <div className="col-span-5">
                        <TextArea
                          value={item.description}
                          rows={1}
                          onChange={e => handleLineItemChange('description', e.target.value, index)}
                          disabled={item.type === lineItemTypesV2.creditNotes.numbers.projectAllocations}
                        />
                      </div>
                      <div className="col-span-2">
                        <InputSimple
                          label=""
                          value={lineItemTypesV2.creditNotes.strings[item?.type]}
                          type="text"
                          disabled
                          hideLockIcon
                        />
                      </div>
                      <div className="col-span-2 mt-1">
                        <InputDateRange
                          startDate={item?.from_date ? new Date(item.from_date) : null}
                          endDate={item?.to_date ? new Date(item.to_date) : null}
                          onChange={dateArray => {
                            (dateArray[0] !== item?.from_date || dateArray[1] !== item?.to_date) &&
                              handleLineItemChange('dateRange', dateArray, index);
                          }}
                          disabled
                        />
                      </div>
                      <div className="col-span-1">
                        <InputSimple
                          hideLockIcon
                          max={item.maxUnits}
                          min={1}
                          value={formatNumber(item?.totals?.creditedUnits ? item?.totals?.creditedUnits : item.units, true, 6)}
                          label=""
                          disabled={item.type === lineItemTypesV2.creditNotes.numbers.projectAllocations}
                          type={isAllocationsLine(item?.type) ? 'text' : 'number'}
                          onChange={e => handleLineItemChange('units', e.target.value, index)}
                        />
                      </div>
                      <div className="col-span-2">
                        <InputSimple
                          value={item?.uom}
                          hideLockIcon
                          label=""
                          disabled={!customCreditNote}
                          type="text"
                          onChange={e => handleLineItemChange('uom', e.target.value, index)}
                        />
                      </div>
                      <div className="col-span-2">
                        <InputSimple
                          type="number"
                          value={item.price}
                          disabled={item.type === lineItemTypesV2.creditNotes.numbers.projectAllocations || !customCreditNote}
                          label=""
                          hideLockIcon
                          onChange={e => handleLineItemChange('price', e.target.value, index)}
                        />
                      </div>
                      <div className={`col-span-1`}>
                        <InputSimple
                          type="number"
                          disabled={item.type === lineItemTypesV2.creditNotes.numbers.projectAllocations || !customCreditNote}
                          label=""
                          value={item.vat_rate}
                          hideLockIcon
                          onChange={e => handleLineItemChange('vat_rate', e.target.value, index)}
                        />
                      </div>
                      <div className={`col-span-1 space-x-1 flex items-center mx-auto`}>
                        <DotsCircleHorizontalIcon
                          className="h-7 w-7 cursor-pointer text-gray-300 hover:text-thaleria-orange-700"
                          onClick={() => handleShowAddLineItemModal(index)}
                        />
                        <XCircleIcon
                          className="h-7 w-7 cursor-pointer text-gray-300 hover:text-red-400"
                          onClick={() => setLineItems(prev => prev.filter((e, i) => i !== index))}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="col-span-2 text-sm text-gray-400 my-2">No line items added yet.</div>
          )}
          <ButtonWhite
            text="Add line"
            disabled={!creditNoteData.client_id || creditNoteData.accounts_receivable_id !== 'custom'}
            icon={<PlusMedium style="w-4 h-4" onClick={null} />}
            onClick={() => handleShowAddLineItemModal(null)}
          />
        </div>
        <div className="col-span-2">
          <TextArea
            rows={2}
            label="VAT disclaimer"
            onChange={e =>
              setCreditNoteData(prev => ({ ...prev, metadata: { ...prev.metadata, vat_disclaimer: e.target.value } }))
            }
            value={creditNoteData.metadata?.vat_disclaimer || ''}
          />
        </div>
        <div className="col-span-2">
          <TextArea
            rows={2}
            label="Footer comment"
            disabled={!creditNoteData.accounts_receivable_id}
            error={formErrors.find(e => e.field === 'footer_comment')?.msg}
            onChange={e =>
              setCreditNoteData(prev => ({ ...prev, metadata: { ...prev.metadata, footer_comment: e.target.value } }))
            }
            value={creditNoteData.metadata.footer_comment}
          />
        </div>
        <div className="col-span-2 flex justify-end items-center gap-x-8">
          <div className="flex justify-between space-x-4 mr-8 mt-.5">
            <div>
              <strong>Total:</strong> {formatCurrency(creditNoteData?.amount)}
            </div>
            <div>
              <strong>Vat:</strong> {formatCurrency(creditNoteData?.vat)}
            </div>
            <div>
              <strong>Grand total:</strong> {formatCurrency(creditNoteData?.amount_and_vat)}
            </div>
          </div>
          <ButtonPrimary text={'Create credit note'} onClick={handleCreateCreditNote} isLoading={isLoading === 'create'} />
        </div>
      </form>
    </div>
  );
}

export default CreateCreditNoteForm;
