import React, { useEffect, useState, useMemo } from 'react';
import DividerWithLabel from '../../../components/Dividers/DividerWithLabel';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import InputSimple from '../../../components/Inputs/InputSimple';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import Checkbox from '../../../components/Checkbox/Checkbox';
import RefreshButton from '../../../components/Buttons/RefreshButton';
import NewTabOpener from '../../../components/NewTabOpener/NewTabOpener';
import { date } from '../../../helpers/date';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import contractInvoicingTypes from '../../../helpers/enum/contractInvoicingTypes';
import formatNumber from '../../../utils/formatNumber';
import format from 'date-fns/format';
import formatCurrency from '../../../utils/formatCurrency';
import billingUnits from '../../../helpers/enum/billingUnits';

function EditSalesOrderAndSpecificContract({
  specificContract,
  setSpecificContract,
  salesOrder,
  setSalesOrder,
  formErrors,
  salesOrderOpenEnded,
  setSalesOrderOpenEnded,
  specificContractOpenEnded,
  setSpecificContractOpenEnded,
  clients,
  contacts,
  fetchAllContacts,
  fetchAllClients,
  edit,
  specificContractOriginalData,
  salesOrderOriginalData,
  countries,
  fetchAllCountries,
  setShowUpdateMsModal,
}) {
  let clientsListboxData = useMemo(() => {
    let array = [];
    if (clients.length) {
      clients.map(client => {
        array.push({
          value: client.id,
          label: client.name,
        });
      });
    }
    return array;
  }, [clients]);

  let contactsListboxData = useMemo(() => {
    let array = [];
    if (contacts.length) {
      contacts.map(contact => {
        array.push({
          value: contact.id,
          label: contact.first_name + ' ' + contact.last_name,
        });
      });
    }
    return array;
  }, [contacts]);

  const countriesListboxData = useMemo(() => {
    let array = [];
    if (countries?.length) {
      countries.forEach(c => {
        array.push({
          value: c.id,
          label: c.name,
        });
      });
    }
    return array;
  }, [countries]);

  const handleSpecificContractChange = (e, label) => {
    //add logic to update value field if invoicing_type OR days_ordered OR daily_rate changes. Also, if type changes, the value should probably be reset
    let newValue;
    if (specificContract.invoicing_type === contractInvoicingTypes.numbers.timeAndMeans) {
      if (label === 'days_ordered') {
        newValue =
          e !== null && specificContract.daily_rate
            ? formatNumber(Number(specificContract.daily_rate) * Number(e?.target?.value))
            : null;
      }

      if (label === 'daily_rate') {
        newValue =
          e !== null && specificContract.days_ordered
            ? formatNumber(Number(specificContract.days_ordered) * Number(e?.target?.value))
            : null;
      }
    }

    setSpecificContract(prev => ({
      ...prev,
      [label]: e?.target ? e?.target?.value || null : e,
      ...(newValue !== undefined && { value: newValue }),
    }));
  };

  const handleSalesOrderChange = (e, label) => {
    //add logic to update value field if invoicing_type OR days_ordered OR daily_rate changes. Also, if type changes, the value should probably be reset
    let newValue;
    if (salesOrder.invoicing_type === contractInvoicingTypes.numbers.timeAndMeans) {
      if (label === 'days_ordered') {
        newValue =
          e !== null && salesOrder.daily_rate ? formatNumber(Number(salesOrder.daily_rate) * Number(e?.target?.value)) : null;
      }

      if (label === 'daily_rate') {
        newValue =
          e !== null && salesOrder.days_ordered ? formatNumber(Number(salesOrder.days_ordered) * Number(e?.target?.value)) : null;
      }
    }

    if (newValue) {
      setSalesOrder(prev => ({
        ...prev,
        [label]: e?.target ? e?.target?.value || null : e,
        value: newValue,
      }));
    } else {
      setSalesOrder(prev => ({ ...prev, [label]: e?.target ? e?.target?.value || null : e }));
    }
  };

  const handleUpdateOpenEndedSpecificContract = e => {
    let state = e.target.checked;
    setSpecificContractOpenEnded(state);
    if (state) setSpecificContract(prev => ({ ...prev, order_end: null }));
    else setSpecificContract(prev => ({ ...prev, order_end: specificContractOriginalData.order_end }));
  };

  const handleUpdateOpenEndedSalesOrder = e => {
    let state = e.target.checked;
    setSalesOrderOpenEnded(state);
    if (state) setSalesOrder(prev => ({ ...prev, order_end: null }));
    else setSalesOrder(prev => ({ ...prev, order_end: salesOrderOriginalData.order_end }));
  };

  const invoicingTypeListBoxData = Object.keys(contractInvoicingTypes.strings).map(key => ({
    value: key,
    label: contractInvoicingTypes.strings[key],
  }));

  const disableEditSC = useMemo(() => {
    if (specificContract?.invoicing_type === contractInvoicingTypes?.numbers?.fixedPrice && specificContract?.ms_completed)
      return true;
    return false;
  }, [specificContract]);

  const disableEditSO = useMemo(() => {
    if (salesOrder?.invoicing_type === contractInvoicingTypes?.numbers?.fixedPrice && salesOrder?.ms_completed) return true;
    return false;
  }, [salesOrder]);

  const milestoneUpdateText = type => {
    let date = null;
    switch (type) {
      case 'so':
        date = salesOrder?.ms_completed;
        break;
      case 'sc':
        date = specificContract?.ms_completed;
        break;
      default:
        return;
    }

    let content = date ? format(new Date(date), 'dd/MM/yyyy') : 'Ongoing';
    let updateText = date ? 'change' : 'mark as completed';
    const updateElement = (
      <span>
        {' ('}
        <span onClick={() => setShowUpdateMsModal(type)} className="text-blue-400 cursor-pointer hover:underline">
          {updateText}
        </span>
        {')'}
      </span>
    );

    return (
      <span>
        {content}
        {updateElement}
      </span>
    );
  };

  return (
    <TwoColumnForm
      label="Specific contract and sales order"
      description="Please select the specific contract and sales order applicable to this purchase order."
    >
      <DividerWithLabel label="Specific contract" />
      {!edit ? (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <SimpleEntry label={'Reference'} data={specificContract.contract_ref || '-'} style={`sm:col-span-1`} />
          <SimpleEntry label={'Description'} data={specificContract.description || '-'} style={`sm:col-span-1`} />
          <SimpleEntry label={'Start date'} data={date(specificContract.order_start || '-')} style={`sm:col-span-1`} />
          <SimpleEntry
            label={'End date'}
            data={(specificContract.order_end && date(specificContract.order_end)) || 'Open Ended'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Invoicing type'}
            data={contractInvoicingTypes.strings[specificContract.invoicing_type]}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Price (€/day)*'}
            data={specificContract.daily_rate ? formatCurrency(specificContract.daily_rate, false) : '-'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Days ordered'}
            data={specificContract.days_ordered ? formatNumber(specificContract.days_ordered, true) : 'FTE'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={`${specificContract.days_ordered ? 'Remaining days' : 'Consumed days'}`}
            data={`${
              specificContract.days_ordered
                ? formatNumber(
                    new Number(specificContract.days_ordered).valueOf() - new Number(specificContract.days_consumed).valueOf(),
                    true,
                  )
                : specificContract.days_consumed || '-'
            }`}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Value (€)'}
            data={specificContract?.value ? formatCurrency(specificContract.value, false) : '-'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry label={'Department'} data={specificContract.department || '-'} style={`sm:col-span-1`} />
          <SimpleEntry
            label={'Client'}
            data={(specificContract.client && specificContract.client.name) || '-'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Country'}
            data={(specificContract?.country_id && specificContract?.country?.name) || '-'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Point of Contact'}
            data={
              (specificContract.pointOfContact &&
                specificContract.pointOfContact.first_name + ' ' + specificContract.pointOfContact.last_name) ||
              '-'
            }
            style={`sm:col-span-1`}
          />
          {specificContract?.invoicing_type === contractInvoicingTypes?.numbers?.fixedPrice && (
            <SimpleEntry label={'Milestone completed'} data={milestoneUpdateText('sc')} style={`sm:col-span-1`} />
          )}
          <SimpleEntry label={'Created'} data={date(specificContract.created_at) || '-'} style={`sm:col-span-1`} />
          <SimpleEntry label={'Last updated'} data={date(specificContract.updated_at) || '-'} style={`sm:col-span-1`} />
        </dl>
      ) : (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <InputSimple
            label={'Reference*'}
            onChange={handleSpecificContractChange}
            error={formErrors.find(e => e.field === 'specificContract-contract_ref')?.msg}
            dataLabel={'contract_ref'}
            value={specificContract.contract_ref}
            disabled={disableEditSC}
          />
          <InputSimple
            label={'Description'}
            error={formErrors.find(e => e.field === 'specificContract-description')?.msg}
            dataLabel={'description'}
            onChange={handleSpecificContractChange}
            value={specificContract.description}
            disabled={disableEditSC}
          />
          <InputDate
            label={'Start Date*'}
            error={formErrors.find(e => e.field === 'specificContract-order_start')?.msg}
            onChange={value => handleSpecificContractChange(value, 'order_start')}
            selected={specificContract.order_start && new Date(specificContract.order_start)}
            disabled={disableEditSC}
          />
          <div className="flex">
            <InputDate
              disabled={disableEditSC || specificContractOpenEnded}
              label={'End Date*'}
              error={formErrors.find(e => e.field === 'specificContract-order_end')?.msg}
              onChange={value => handleSpecificContractChange(value, 'order_end')}
              selected={specificContract.order_end && new Date(specificContract.order_end)}
            />
            <Checkbox
              className="ml-4"
              value={specificContractOpenEnded}
              onChange={handleUpdateOpenEndedSpecificContract}
              title="Open Ended"
              disabled={disableEditSC}
            />
          </div>
          <ReactSelect
            label="Invoicing type*"
            options={invoicingTypeListBoxData}
            placeholder="Select type"
            error={formErrors.find(e => e.field === 'specificContract-invoicing_type')?.msg}
            selectedOptionsIds={[specificContract.invoicing_type.toString()]}
            disabled={true}
          />
          <InputSimple
            type="number"
            label={'Price (€/day)*'}
            error={formErrors.find(e => e.field === 'specificContract-daily_rate')?.msg}
            dataLabel={'daily_rate'}
            onChange={handleSpecificContractChange}
            value={specificContract.daily_rate}
            disabled={disableEditSC || Number(specificContract.invoicing_type) === contractInvoicingTypes.numbers.fixedPrice}
          />
          <InputSimple
            type="number"
            label={'Days ordered*'}
            dataLabel={'days_ordered'}
            step=".5"
            error={formErrors.find(e => e.field === 'specificContract-days_ordered')?.msg}
            onChange={handleSpecificContractChange}
            value={specificContract.days_ordered}
            placeholder="FTE"
            disabled={disableEditSC}
          />
          <InputSimple
            type="number"
            label={specificContract.invoicing_type !== contractInvoicingTypes.numbers.fixedPrice ? 'Value (€)' : 'Value (€)*'}
            dataLabel="value"
            error={formErrors.find(e => e.field === 'specificContract-value')?.msg}
            onChange={handleSpecificContractChange}
            value={specificContract.value}
            disabled={disableEditSC || specificContract.invoicing_type !== contractInvoicingTypes.numbers.fixedPrice}
          />
          <InputSimple
            label={'Department'}
            dataLabel={'department'}
            error={formErrors.find(e => e.field === 'specificContract-department')?.msg}
            onChange={handleSpecificContractChange}
            value={specificContract.department}
            disabled={disableEditSC}
          />
          <div className="flex flex-col">
            <div className="flex items-end">
              <ReactSelect
                isSearchable
                label={'Client*'}
                error={formErrors.find(e => e.field === 'specificContract-client_id')?.msg}
                options={clientsListboxData}
                selectedOptionsIds={[specificContract.client_id]}
                onChange={el => handleSpecificContractChange(el.value, 'client_id')}
                disabled={disableEditSC}
              />
              <RefreshButton onClick={() => fetchAllClients()} />
            </div>
            <NewTabOpener link={'/admin-panel/admin/clients/create'} title="Add New Client" />
          </div>
          <div className="flex flex-col">
            <div className="flex items-end">
              <ReactSelect
                isSearchable
                label={'Country*'}
                error={formErrors.find(e => e.field === 'specificContract-country_id')?.msg}
                options={countriesListboxData}
                selectedOptionsIds={[specificContract.country_id]}
                onChange={el => handleSpecificContractChange(el.value, 'country_id')}
                disabled={disableEditSC}
              />
              <RefreshButton onClick={() => fetchAllCountries()} />
            </div>
            <NewTabOpener link={'/admin-panel/admin/countries/create'} title="Add New Country" />
          </div>
          <div className="flex flex-col">
            <div className="flex items-end">
              <ReactSelect
                isSearchable
                error={formErrors.find(e => e.field === 'specificContract-point_of_contact_id')?.msg}
                label={'Point of contact'}
                options={contactsListboxData}
                selectedOptionsIds={[specificContract.point_of_contact_id]}
                //When isClearable option is turned on, the element (el) returned is null so we cannot access value
                onChange={el => handleSpecificContractChange(el ? el.value : null, 'point_of_contact_id')}
                isClearable
                disabled={disableEditSC}
              />
              <RefreshButton onClick={() => fetchAllContacts()} />
            </div>
            <NewTabOpener link={'/admin-panel/admin/points-of-contact/create'} title="Add New Point of contact" />
          </div>
        </dl>
      )}
      <DividerWithLabel label="Sales order" />
      {!edit ? (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <SimpleEntry label={'Reference'} data={salesOrder.order_ref || '-'} style={`sm:col-span-1`} />
          <SimpleEntry label={'Description'} data={salesOrder.description || '-'} style={`sm:col-span-1`} />
          <SimpleEntry label={'Start date'} data={date(salesOrder.order_start || '-')} style={`sm:col-span-1`} />
          <SimpleEntry
            label={'End date'}
            data={(salesOrder.order_end && date(salesOrder.order_end)) || 'Open Ended'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Invoicing type'}
            data={contractInvoicingTypes.strings[salesOrder.invoicing_type]}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Price (€/day)'}
            data={salesOrder.daily_rate ? formatCurrency(salesOrder.daily_rate, false) : '-'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Billing units'}
            style={`sm:col-span-1`}
            data={
              salesOrder?.invoicing_type === contractInvoicingTypes?.numbers?.fixedPrice
                ? '-'
                : billingUnits.strings[salesOrder?.billing_units]
            }
          />
          <SimpleEntry
            label={'Days ordered'}
            data={salesOrder.days_ordered ? formatNumber(salesOrder.days_ordered, true) : 'FTE'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={`${salesOrder.days_ordered ? 'Remaining days' : 'Consumed days'}`}
            data={`${
              salesOrder.days_ordered
                ? formatNumber(
                    new Number(salesOrder.days_ordered).valueOf() - new Number(salesOrder.days_consumed).valueOf(),
                    true,
                  )
                : salesOrder.days_consumed || '-'
            }`}
            style={`sm:col-span-1`}
          />
          <SimpleEntry
            label={'Value (€)'}
            data={salesOrder?.value ? formatCurrency(salesOrder.value, false) : '-'}
            style={`sm:col-span-1`}
          />
          <SimpleEntry label={'Department'} data={salesOrder.department || '-'} style={`sm:col-span-1`} />
          <SimpleEntry label={'Client'} data={(salesOrder.client && salesOrder.client.name) || '-'} style={`sm:col-span-1`} />
          <SimpleEntry
            label={'Point of Contact'}
            data={
              (salesOrder.pointOfContact && salesOrder.pointOfContact.first_name + ' ' + salesOrder.pointOfContact.last_name) ||
              '-'
            }
            style={`sm:col-span-1`}
          />
          {salesOrder?.invoicing_type === contractInvoicingTypes.numbers.fixedPrice && (
            <SimpleEntry label={'Milestone completed'} data={milestoneUpdateText('so')} style={`sm:col-span-1`} />
          )}
          <SimpleEntry label={'Created'} data={date(salesOrder.created_at) || '-'} style={`sm:col-span-1`} />
          <SimpleEntry label={'Last updated'} data={date(salesOrder.updated_at) || '-'} style={`sm:col-span-1`} />
        </dl>
      ) : (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <InputSimple
            error={formErrors.find(e => e.field === 'salesOrder-order_ref')?.msg}
            label={'Reference*'}
            onChange={handleSalesOrderChange}
            dataLabel={'order_ref'}
            value={salesOrder.order_ref}
            disabled={disableEditSO}
          />
          <InputSimple
            label={'Description'}
            dataLabel={'description'}
            error={formErrors.find(e => e.field === 'salesOrder-description')?.msg}
            onChange={handleSalesOrderChange}
            value={salesOrder.description}
            disabled={disableEditSO}
          />
          <InputDate
            label={'Start Date*'}
            error={formErrors.find(e => e.field === 'salesOrder-order_start')?.msg}
            onChange={value => handleSalesOrderChange(value, 'order_start')}
            selected={salesOrder.order_start && new Date(salesOrder.order_start)}
            disabled={disableEditSO}
          />
          <div className="flex">
            <InputDate
              disabled={disableEditSO || salesOrderOpenEnded}
              label={'End Date*'}
              error={formErrors.find(e => e.field === 'salesOrder-order_end')?.msg}
              onChange={value => handleSalesOrderChange(value, 'order_end')}
              selected={salesOrder.order_end && new Date(salesOrder.order_end)}
            />
            <Checkbox
              className="ml-4"
              value={salesOrderOpenEnded}
              onChange={handleUpdateOpenEndedSalesOrder}
              title="Open Ended"
              disabled={disableEditSO}
            />
          </div>
          <ReactSelect
            label="Invoicing type*"
            options={invoicingTypeListBoxData}
            placeholder="Select type"
            error={formErrors.find(e => e.field === 'salesOrder-invoicing_type')?.msg}
            selectedOptionsIds={[salesOrder.invoicing_type.toString()]}
            disabled={true}
          />
          <InputSimple
            type="number"
            label={'Price (€/day)*'}
            error={formErrors.find(e => e.field === 'salesOrder-daily_rate')?.msg}
            dataLabel={'daily_rate'}
            onChange={handleSalesOrderChange}
            value={salesOrder.daily_rate}
            disabled={disableEditSO || Number(salesOrder.invoicing_type) === contractInvoicingTypes.numbers.fixedPrice}
          />
          <ReactSelect
            label="Billing units"
            selectedOptionsIds={[salesOrder?.billing_units]}
            error={formErrors.find(e => e.field === 'salesOrder-billing_units')?.msg}
            onChange={e => handleSalesOrderChange(e?.value, 'billing_units')}
            disabled={salesOrder?.invoicing_type === contractInvoicingTypes.numbers.fixedPrice}
            options={Object.keys(billingUnits.strings).map(key => {
              return {
                value: Number(key),
                label: billingUnits.strings[key],
              };
            })}
          />
          <InputSimple
            type="number"
            label={'Days ordered*'}
            step=".5"
            error={formErrors.find(e => e.field === 'salesOrder-days_ordered')?.msg}
            dataLabel={'days_ordered'}
            onChange={handleSalesOrderChange}
            value={salesOrder.days_ordered}
            placeholder="FTE"
            disabled={disableEditSO}
          />
          <InputSimple
            type="number"
            label={salesOrder.invoicing_type !== contractInvoicingTypes.numbers.fixedPrice ? 'Value (€)' : 'Value (€)*'}
            dataLabel="value"
            error={formErrors.find(e => e.field === 'salesOrder-value')?.msg}
            onChange={handleSalesOrderChange}
            value={salesOrder.value}
            disabled={disableEditSO || salesOrder.invoicing_type !== contractInvoicingTypes.numbers.fixedPrice}
          />
          <InputSimple
            label={'Department'}
            error={formErrors.find(e => e.field === 'salesOrder-department')?.msg}
            dataLabel={'department'}
            onChange={handleSalesOrderChange}
            value={salesOrder.department}
            disabled={disableEditSO}
          />
          <div className="flex flex-col">
            <div className="flex items-end">
              <ReactSelect
                isSearchable
                label={'Client'}
                error={formErrors.find(e => e.field === 'salesOrder-client_id')?.msg}
                options={clientsListboxData}
                selectedOptionsIds={[salesOrder?.client_id]}
                onChange={el => handleSalesOrderChange(el.value, 'client_id')}
                disabled={disableEditSO}
              />
              <RefreshButton onClick={() => fetchAllClients()} />
            </div>
            <NewTabOpener link={'/admin-panel/admin/clients/create'} title="Add New Client" />
          </div>
          <div className="flex flex-col">
            <div className="flex items-end">
              <ReactSelect
                isSearchable
                label={'Point of contact'}
                error={formErrors.find(e => e.field === 'salesOrder-point_of_contact_id')?.msg}
                options={contactsListboxData}
                selectedOptionsIds={[salesOrder?.point_of_contact_id]}
                //When isClearable option is turned on, the element (el) returned is null so we cannot access value
                onChange={el => handleSalesOrderChange(el ? el.value : null, 'point_of_contact_id')}
                isClearable
                disabled={disableEditSO}
              />
              <RefreshButton onClick={() => fetchAllContacts()} />
            </div>
            <NewTabOpener link={'/admin-panel/admin/points-of-contact/create'} title="Add New Point of contact" />
          </div>
        </dl>
      )}
    </TwoColumnForm>
  );
}

export default EditSalesOrderAndSpecificContract;
