import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import TabList from '../TabList/TabList';
import useApi from '../../hooks/useApi';
import paidStatus from '../../helpers/enum/paidStatus';
import InvoiceTabItem from './InvoiceTabItem';

const MyInvoicesTabList = () => {
  const location = useLocation();
  const hash = location.hash;

  const {
    accountsPayable: { getMyInvoices },
  } = useApi();
  let query = useQuery();
  const page = Number(query.get('page')) || 1;

  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMyInvoices().then(res => {
      setLoading(false);
      setInvoices(res);
    });
  }, []);

  const invoicesData = useMemo(() => {
    let data = [];
    if (invoices && invoices.length) {
      invoices.forEach(invoice =>
        data.push({
          id: invoice.id,
          paid: invoice.paid,
          payment_date: invoice.payment_date,
          total_and_vat: invoice.cost_and_vat,
          iban: invoice.iban,
          due_date: invoice.due_date,
          invoice_number: invoice.invoice_number,
          created_at: invoice.created_at,
        }),
      );
    }
    return data;
  }, [invoices]);

  const nbOfPages = useMemo(() => {
    let itemCount = 0;
    invoicesData?.forEach(lr => {
      if (hash) {
        if (hash.includes(lr.statusId)) itemCount += 1;
      } else {
        if (lr.statusId === 0) itemCount += 1;
      }
    });

    let pageSize = 5;
    if (itemCount <= pageSize) return 1;
    else return Math.ceil(itemCount / pageSize);
  }, [invoicesData, hash]);

  const tabs = useMemo(() => {
    let count = {
      [paidStatus.numbers.unpaid]: 0,
      [paidStatus.numbers.partiallyPaid]: 0,
      [paidStatus.numbers.paid]: 0,
    };

    invoicesData?.forEach(el => {
      count[el.paid] += 1;
    });

    return [
      {
        name: paidStatus.strings[paidStatus.numbers.unpaid],
        href: '/my-invoices',
        count: count[paidStatus.numbers.unpaid],
        current: !hash,
      },
      {
        name: paidStatus.strings[paidStatus.numbers.partiallyPaid],
        href: '#' + paidStatus.numbers.partiallyPaid,
        count: count[paidStatus.numbers.partiallyPaid],
        current: hash.includes(paidStatus.numbers.partiallyPaid),
      },
      {
        name: paidStatus.strings[paidStatus.numbers.paid],
        href: '#' + paidStatus.numbers.paid,
        count: count[paidStatus.numbers.paid],
        current: hash.includes(paidStatus.numbers.paid),
      },
    ];
  }, [invoicesData, hash]);

  const renderInvoicesList = () => {
    if (!invoicesData?.length) return <li className="mt-8">No invoices to show</li>;
    const filterInvoices = invoicesData
      .filter(i => {
        if (hash) {
          if (hash.includes(i.paid)) return i;
        } else {
          if (i.paid === 0) return i;
        }
      })
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const paginatedInvoices = filterInvoices.slice(((page || 1) - 1) * 5, (page || 1) * 5);

    const invoiceItems = paginatedInvoices.map(i => <InvoiceTabItem invoice={i} />);

    if (invoiceItems?.length) return invoiceItems;
    else return <li className="mt-8">No invoices to show</li>;
  };

  return (
    <div>
      <TabList
        page={page}
        isLoading={loading}
        renderList={renderInvoicesList}
        tabs={tabs}
        pageCount={nbOfPages}
        href="/leave-request"
      />
    </div>
  );
};

export default MyInvoicesTabList;
