const contractInvoicingTypes = {
  numbers: {
    timeAndMeans: 1,
    fixedPrice: 2,
  },
  strings: {
    1: 'Time and means',
    2: 'Fixed price',
  },
};

export default contractInvoicingTypes;
