import React, { useState, useEffect, useRef } from 'react';
import Card from '../../../components/Cards/Card';
import InputSimple from '../../../components/Inputs/InputSimple';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import { PlusCircleIcon, ReceiptTaxIcon, TrashIcon } from '@heroicons/react/outline';
import formatNumber from '../../../utils/formatNumber';
import useApi from '../../../hooks/useApi';
import { useDispatch } from 'react-redux';
import TextArea from '../../../components/Inputs/TextArea';
import InputRadio from '../../../components/Inputs/InputRadio';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import Error from '../../../components/Error/Error';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import AttachmentCard from '../../../components/Attachments/AttachmentCard';
import { ClipLoader } from 'react-spinners';
import { showSuccessNotification } from '../../../store/app/actions';
import { useHistory, useLocation } from 'react-router-dom';
import getNext7thAfter21Days from '../../../helpers/getNext7thAfter21Days';
import './FormTableStyle.css';
import InfoTooltip from '../../../components/Tooltips/InfoTooltip';
import SimpleAlert from '../../../components/Modals/SimpleAlert';
import AccountsPayableLineItemEdits from '../../../components/Tables/AccountsPayableLineItemEdits';
import EditLineItemDecimalPlaces from '../../../components/Modals/EditLineItemDecimalPlaces';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import { lineItemTypesV2 } from '../../../helpers/enum/lineItemTypes';

const CreateUserInvoiceForm = () => {
  const checkAllRef = useRef();
  const inputInvoice = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const stateUserId = location?.state?.user_id;

  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [iban, setIban] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [staffContract, setStaffContract] = useState(null);
  const [customLine, setCustomLine] = useState(null);
  const [availableLines, setAvailableLines] = useState([]);
  const [activeStaffOrders, setActiveStaffOrders] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [amountMatch, setAmountMatch] = useState(null);
  const [message, setMessage] = useState('');
  const [formErrors, setFormErrors] = useState([]);
  const [dueDate, setDueDate] = useState(() => getNext7thAfter21Days(new Date()));
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [staffContractOptions, setStaffContractOptions] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [deleteLineAlert, setDeleteLineAlert] = useState(false);
  const [showEditDecimalPlaces, setShowEditDecimalPlaces] = useState(false);

  const [totals, setTotals] = useState({
    total: 0,
    vat: 0,
    total_and_vat: 0,
  });

  const {
    lineItems: { getAvailableAPLines, deleteAPAdjustmentLineItem },
    purchaseOrders: { getActiveStaffOrders },
    accountsPayable: { createAccountsPayable },
    users: { getAllUsers },
  } = useApi();

  useEffect(() => {
    getAllUsers().then(res => {
      setUserOptions(res.map(u => ({ value: u.id, label: `${u.first_names} ${u.last_names}` })));
    });
  }, []);

  useEffect(() => {
    if (stateUserId) {
      setSelectedUserId(stateUserId);
    }
  }, [stateUserId]);

  const resetState = () => {
    setIban(null);
    setStaffContract(null);
    setCompanyName(null);
    setInvoiceNumber(null);
    setAvailableLines([]);
    setActiveStaffOrders([]);
    setCheckAll(false);
    setAmountMatch(null);
    setMessage('');
    setFormErrors([]);
    setDueDate(getNext7thAfter21Days(new Date()));
    setInvoiceFile(null);
    setStaffContractOptions([]);
  };

  useEffect(() => {
    resetState();
    if (!selectedUserId) return;
    getAvailableAPLines(selectedUserId).then(res => {
      if (res.length) {
        const lines = res.map(line => ({
          ...line,
          metadata: JSON.parse(line?.metadata),
          checked: true,
        }));
        setAvailableLines(lines);
        //all available lines will appear checked
        setCheckAll(true);
      }
    });
    getActiveStaffOrders(selectedUserId).then(res => {
      setActiveStaffOrders(res);
    });
  }, [selectedUserId]);

  useEffect(() => {
    let total = 0;
    let vat = 0;

    availableLines.forEach(line => {
      if (line.checked) {
        total += Number(line.total);
        if (line?.vat) vat += Number(line.vat);
      }
    });
    setTotals({
      total,
      vat,
      total_and_vat: total + vat,
    });
  }, [availableLines]);

  const handleCheckLine = index => {
    const updatedLines = availableLines.map((line, i) => ({
      ...line,
      checked: i === index ? !line.checked : line.checked,
    }));
    setAvailableLines(updatedLines);

    const allChecked = updatedLines.every(line => line.checked);
    const someChecked = updatedLines.some(line => line.checked);

    setCheckAll(allChecked);
    if (checkAllRef.current) {
      checkAllRef.current.indeterminate = !allChecked && someChecked;
    }
  };

  useEffect(() => {
    if (customLine !== null) {
      let total = null;
      let vat = null;
      let total_and_vat = null;

      if (customLine?.units != null && customLine?.price != null) {
        total = customLine?.units * customLine?.price;
        vat = customLine?.vat_rate != null ? total * (customLine?.vat_rate / 100) : 0;
        total_and_vat = total + vat;
      }

      setCustomLine(prevState => ({
        ...prevState,
        total,
        vat,
        total_and_vat,
      }));
    }
  }, [customLine?.units, customLine?.price, customLine?.vat_rate]);

  const saveCustomLine = () => {
    setAvailableLines(prev => [...prev, customLine]);
    setCustomLine(null);
  };

  const handleCheckAll = () => {
    const newCheckAll = !checkAll;
    const updatedLines = availableLines.map(line => ({
      ...line,
      checked: newCheckAll,
    }));
    setAvailableLines(updatedLines);
    setCheckAll(newCheckAll);
  };

  const checkFormErrors = () => {
    let errors = [];
    if (!staffContract)
      errors.push({
        field: 'staffContract',
        msg: 'This field is required',
      });
    if (!invoiceNumber)
      errors.push({
        field: 'invoiceNumber',
        msg: 'This field is required',
      });
    if (!amountMatch && (!message || message === ''))
      errors.push({
        field: 'message',
        msg: "This field is required when invoices' amounts don't match",
      });
    if (!availableLines.some(li => li.checked))
      errors.push({
        field: 'invoiceLines',
        msg: 'At least one line item must be checked to create the invoice request',
      });
    if (!invoiceFile) {
      errors.push({
        field: 'invoiceFile',
        msg: 'An invoice file is required',
      });
    }
    if (amountMatch === null) {
      errors.push({
        field: 'amountMatch',
        msg: 'This field is required.',
      });
    }

    return errors;
  };

  const onUploadClick = () => {
    setIsUploading(true);
    const errors = checkFormErrors();
    if (errors?.length) {
      setFormErrors(errors);
      setIsUploading(false);
      const parentContainer = document.getElementById('createUserInvoiceForm');
      if (parentContainer) {
        parentContainer.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
      return;
    }

    setFormErrors([]);

    const lineItemsToAdd = [];

    availableLines.forEach(li => {
      if (li?.checked) {
        if (li?.id) {
          let lineItem = { id: li.id };
          //if decimal places were changed, send it with the line items
          if (li?.metadata?.new_decimal_places) {
            lineItem.metadata = { decimal_places: Number(li?.metadata?.new_decimal_places) };
          }
          lineItemsToAdd.push(lineItem);
        }
        if (li?.uuid) {
          const po = activeStaffOrders.find(po => po.id === li?.staff_order_id);
          lineItemsToAdd.push({
            description: li?.description,
            units: li?.units,
            uom: li?.uom,
            price: li?.price,
            vat_rate: li?.vat_rate,
            type: li?.type,
            staff_order_id: li?.staff_order_id,
            framework_contract_id: po?.framework_contract_id,
            partner_contract_id: po?.partner_contract_id,
            sales_order_id: po?.sales_order_id,
            user_id: selectedUserId,
          });
        }
      }
    });

    let apData = {
      invoice_number: invoiceNumber,
      staff_contract_id: staffContract.value,
      due_date: dueDate,
      user_id: selectedUserId,
      amount_match: amountMatch,
      lineItemsToAdd: lineItemsToAdd,
    };

    if (message !== '') apData.comment = message;

    createAccountsPayable(apData, invoiceFile)
      .then(res => {
        setIsUploading(false);
        dispatch(showSuccessNotification('Invoice created!'));
        history.push('/admin-panel/finance/accounts-payable');
      })
      .catch(err => {
        setIsUploading(false);
        throw err;
      });
  };

  const onRemoveHandler = () => {
    inputInvoice.current.value = '';
    setInvoiceFile(null);
  };

  const handleAddInvoiceFile = () => {
    inputInvoice.current.value = '';
    inputInvoice.current.click();
  };

  const defaultFileTypes = `.csv, image/png,
  image/jpg,
  image/jpeg,
  image/gif,
  image/x-png,
  application/pdf,
  application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.openxmlformats-officedocument.wordprocessingml.template,
  application/vnd.ms-word.document.macroEnabled.12,
  application/vnd.ms-word.template.macroEnabled.12,
  application/vnd.ms-excel,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  application/vnd.openxmlformats-officedocument.spreadsheetml.template,
  application/vnd.ms-excel.sheet.macroEnabled.12,
  application/vnd.ms-excel.template.macroEnabled.12,
  application/vnd.ms-excel.sheet.binary.macroEnabled.12,
  application/vnd.ms-excel.addin.macroEnabled.12,
  application/vnd.ms-powerpoint,
  application/vnd.openxmlformats-officedocument.presentationml.template,
  application/vnd.openxmlformats-officedocument.presentationml.slideshow,
  application/vnd.openxmlformats-officedocument.presentationml.presentation,
  application/vnd.ms-powerpoint.presentation.macroEnabled.12,
  application/vnd.ms-powerpoint.template.macroEnabled.12,
  application/vnd.ms-powerpoint.slideshow.macroEnabled.12`;

  const onChangeInvoice = e => {
    setUploading(true);
    e.stopPropagation();
    e.preventDefault();
    let file = e.target.files[0];
    const maxFileSizeInMb = 10;
    if (maxFileSizeInMb) {
      if (file?.size > maxFileSizeInMb * 1000000) {
        alert(`Maximum file size is ${maxFileSizeInMb}mb`);
        return null;
      }
    }
    setInvoiceFile(file);
    //send type as second argument to uploadHandler, if no type, it's 'other' file
  };

  useEffect(() => {
    //iban options vary depending on selected lines for this invoice
    let _staffContractOptions = [];
    let uniqueLabels = new Set();

    availableLines.forEach(li => {
      if (li?.checked && li?.staffOrder) {
        let label = li.staffOrder?.staffContract?.contract_ref;
        let option = {
          value: li.staffOrder.staffContract.id,
          label: label,
          iban: `${li.staffOrder?.staffContract?.iban} - ${li.staffOrder?.staffContract?.bic_swift}`,
          company_name: li.staffOrder?.staffContract?.company_name,
        };
        //remove duplicate staffContracts
        if (!uniqueLabels.has(label)) {
          uniqueLabels.add(label);
          _staffContractOptions.push(option);
        }
      }
    });
    setStaffContractOptions(_staffContractOptions);
    if (_staffContractOptions?.length === 1) {
      setStaffContract(_staffContractOptions[0]);
      setIban(_staffContractOptions[0]?.iban);
      setCompanyName(_staffContractOptions[0]?.company_name);
    } else if (!_staffContractOptions?.find(i => i.value === staffContract?.value)) {
      setStaffContract(null);
      setIban(null);
      setCompanyName(null);
    }
  }, [availableLines]);

  const onUserChangeHandler = e => {
    setSelectedUserId(e.value);
  };

  useEffect(() => {
    if (staffContract) {
      setIban(staffContract?.iban);
      setCompanyName(staffContract?.company_name);
    } else {
      setIban(null);
      setCompanyName(null);
    }
  }, [staffContract]);

  const invoiceNumberLabel = (
    <div className="flex gap-x-1 items-center">
      Invoice number*
      <InfoTooltip
        children={
          "Please make sure to add the invoice number as it appears in the user's invoice file. This number will appear in the bank transfer description and is used for payment tracking purposes."
        }
        iconClassName="h-4 w-4 text-thaleria-orange-700"
      />
    </div>
  );

  const deleteAdjustmentLine = id => {
    deleteAPAdjustmentLineItem(id)
      .then(() => {
        setAvailableLines(prev => prev.filter(l => l.id !== id));
        setDeleteLineAlert(false);
      })
      .catch(e => {
        alert('An error occured. Try again later');
        setDeleteLineAlert(false);
      });
  };

  const saveDecimalPlaces = decimalPlaces => {
    const newLineItems = availableLines.map((li, i) => {
      if (i === showEditDecimalPlaces) {
        const newUnits = formatNumber(li?.metadata?.hours / 8, false, decimalPlaces);
        let isNegativeAmount = li.type === lineItemTypesV2.accountsPayable.numbers.projectAllocationCreditNoteAdjustment;
        const total = newUnits * Number(li.price) * (isNegativeAmount ? -1 : 1);
        const vat = newUnits * Number(li.price) * (Number(li?.vat_rate) / 100);
        const totalAndVat = total + vat;
        return {
          ...li,
          units: newUnits,
          total: total,
          vat: vat,
          total_and_vat: totalAndVat,
          metadata: {
            ...li.metadata,
            new_decimal_places: decimalPlaces,
          },
        };
      }
      return li;
    });
    setAvailableLines(newLineItems);
    setShowEditDecimalPlaces(false);
  };

  return (
    <Card title="Submit user's invoice" className="overflow-auto">
      <EditLineItemDecimalPlaces
        show={showEditDecimalPlaces !== false && !isNaN(showEditDecimalPlaces) ? true : false}
        setShow={setShowEditDecimalPlaces}
        lineItem={availableLines[showEditDecimalPlaces]}
        onConfirm={value => saveDecimalPlaces(value)}
      />
      <SimpleAlert
        errorTitle={'Confirm delete'}
        errorMsg={
          'By confirming you will be permanently and irreversibly deleting this line from the database. Do you want to continue?'
        }
        show={deleteLineAlert ? true : false}
        hide={() => setDeleteLineAlert(false)}
        onAcceptClick={() => deleteAdjustmentLine(deleteLineAlert)}
        onAcceptText={'Delete'}
        onDeclineClick={() => setDeleteLineAlert(false)}
        onDeclineText={'Cancel'}
      />
      <input
        type="file"
        id="invoice"
        accept={defaultFileTypes}
        ref={inputInvoice}
        onChange={onChangeInvoice}
        style={{ display: 'none' }}
      />
      <dl className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="col-span-1">
          <ReactSelect
            label="Select user"
            options={userOptions}
            onChange={e => onUserChangeHandler(e)}
            selectedOptionsIds={[selectedUserId]}
            isSearchable
          />
        </div>
        <div className="col-span-1"></div>
        <div className="col-span-1">
          <InputSimple
            label={invoiceNumberLabel}
            value={invoiceNumber}
            onChange={e => setInvoiceNumber(e.target.value)}
            error={formErrors.find(e => e.field === 'invoiceNumber')?.msg}
          />
        </div>
        <div className="col-span-1">
          <div className="flex items-center mb-1 gap-x-2">
            <span className="text-sm ">Invoice*</span>
            {formErrors.find(e => e.field === 'invoiceFile') && (
              <Error message={formErrors.find(e => e.field === 'invoiceFile').msg} />
            )}
          </div>
          <ul className="divide-y divide-thaleria-blue-200/75 border border-gray-200 rounded-md">
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
              {invoiceFile ? (
                <AttachmentCard
                  document={invoiceFile}
                  enableDownload={false}
                  enableRemove={true}
                  onRemoveHandler={onRemoveHandler}
                  fileIcon={'invoice'}
                />
              ) : (
                <div className="flex justify-start">
                  <ReceiptTaxIcon className="w-5 h-5 text-gray-500" />
                  <span className="ml-2 text-gray-600">Add invoice here</span>
                </div>
              )}
              {!invoiceFile &&
                (uploading === 'invoice' ? (
                  <ClipLoader size={18} color={'#FFA53B'} />
                ) : (
                  <PlusCircleIcon
                    className="w-5 h-5 text-thaleria-orange-700 hover:text-thaleria-orange-800 cursor-pointer"
                    onClick={() => handleAddInvoiceFile()}
                  />
                ))}
            </li>
          </ul>
        </div>
        <div className="col-span-1">
          <ReactSelect
            label="Staff contract*"
            options={staffContractOptions}
            onChange={e => setStaffContract(e)}
            selectedOptions={[staffContract]}
            error={formErrors.find(e => e.field === 'staffContract')?.msg}
          />
        </div>
        <div className="col-span-1">
          <SimpleEntry label="IBAN - BIC/SWIFT" data={iban} />
        </div>
        <InputDate label="Due date*" onChange={value => setDueDate(value)} selected={dueDate} />
        <div className="col-span-1">
          <SimpleEntry label="Company name" data={companyName} />
        </div>
      </dl>
      <div className="mt-8 mb-4 flex flex-col">
        <AccountsPayableLineItemEdits
          availableLines={availableLines}
          customLine={customLine}
          setCustomLine={setCustomLine}
          totals={totals}
          formErrors={formErrors}
          handleCheckAll={handleCheckAll}
          handleCheckLine={handleCheckLine}
          saveCustomLine={saveCustomLine}
          activeStaffOrders={activeStaffOrders}
          checkAll={checkAll}
          setShowEditDecimalPlaces={setShowEditDecimalPlaces}
          handleDeleteAdjustmentLine={setDeleteLineAlert}
        />
        <div>
          <span className="mt-4 py-4 flex justify-between items-center ">
            <InputRadio
              label="Does the amount shown here match the one on the user's invoice?*"
              options={['Yes', 'No']}
              selectedValue={amountMatch === true ? 'Yes' : amountMatch === false ? 'No' : null}
              onChange={e => setAmountMatch(e.target.value === 'Yes' ? true : false)}
              error={formErrors.find(e => e.field === 'amountMatch')?.msg}
            />
          </span>
          <div className="space-y-4 ">
            <div className="my-4 space-y-2">
              <TextArea
                label={amountMatch ? 'Comments' : 'Comments*'}
                value={message}
                onChange={e => setMessage(e.target.value)}
                error={formErrors.find(e => e.field === 'message')?.msg}
              />
            </div>
          </div>
          <div className="w-full flex justify-end mt-4">
            <ButtonPrimary
              text="Upload"
              isLoading={isUploading}
              onClick={() => onUploadClick()}
              disabled={customLine ? true : false}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CreateUserInvoiceForm;
