import React from 'react';
import PageWrapper from '../../../containers/App/PageWrapper';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function ContractsManager({ children, pages }) {
  const baseUrl = '/admin-panel/contracts';
  const location = useLocation();

  const TYPES = [
    {
      name: 'Purchase Orders',
      url: `${baseUrl}/purchase-orders`,
      isActive: location.pathname.includes(`${baseUrl}/purchase-orders`),
    },
    {
      name: 'Framework Contracts',
      url: `${baseUrl}/framework-contracts`,
      isActive: location.pathname.includes(`${baseUrl}/framework-contracts`),
    },
    {
      name: 'Staff Contracts',
      url: `${baseUrl}/staff-contracts`,
      isActive: location.pathname.includes(`${baseUrl}/staff-contracts`),
    },
  ];

  return (
    <PageWrapper pages={pages} noXPadding noYPadding constrainWidth={false}>
      <div className="flex grow">
        <div className="flex w-56 flex-col inset-y-0 min-h-0 border-r border-gray-200 px-4 py-8 space-y-4 bg-gray-700">
          {TYPES.map(el => (
            <Link
              to={el.url}
              className={`${
                el.isActive ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              } group flex items-center px-2 py-2 text-sm font-medium rounded-md transition duration-200`}
            >
              {el.name}
            </Link>
          ))}
        </div>
        <div className="grow flex flex-col items-center" id="main-container">
          <div className="w-full grow h-[700px]">{children}</div>
        </div>
      </div>
    </PageWrapper>
  );
}

export default ContractsManager;
