import React, { useEffect, useState, useMemo } from 'react';
import DividerWithLabel from '../../../components/Dividers/DividerWithLabel';
import TwoColumnForm from '../../../components/Layouts/TwoColumnForm';
import InputSimple from '../../../components/Inputs/InputSimple';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import Checkbox from '../../../components/Checkbox/Checkbox';
import RefreshButton from '../../../components/Buttons/RefreshButton';
import NewTabOpener from '../../../components/NewTabOpener/NewTabOpener';
import Toggle from '../../../components/Toggles/Toggle';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import useApi from '../../../hooks/useApi';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import { date } from '../../../helpers/date';
import contractInvoicingTypes from '../../../helpers/enum/contractInvoicingTypes';
import formatNumber from '../../../utils/formatNumber';
import billingUnits from '../../../helpers/enum/billingUnits';

function AddSalesOrderAndSpecificContract({
  specificContract,
  setSpecificContract,
  salesOrder,
  setSalesOrder,
  formErrors,
  salesOrderOpenEnded,
  setSalesOrderOpenEnded,
  specificContractOpenEnded,
  setSpecificContractOpenEnded,
  clients,
  contacts,
  fetchAllContacts,
  fetchAllClients,
  countries,
  fetchAllCountries,
}) {
  const {
    specificContracts: { getSpecificContractOptions, getSpecificContractById },
    salesOrders: { getSalesOrderOptions, getSalesOrderById },
  } = useApi();

  const [specificContractOptions, setSpecificContractOptions] = useState([]);
  const [selectedSpecificContract, setSelectedSpecificContract] = useState({ value: 0, label: 'Create new' });
  const [salesOrderOptions, setSalesOrderOptions] = useState([]);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState({ value: 0, label: 'Create new' });

  useEffect(() => {
    getSpecificContractOptions().then(res => {
      setSpecificContractOptions(
        res.map(c => ({
          value: c.id,
          label: c.contract_ref,
        })),
      );
    });
    getSalesOrderOptions().then(res => {
      setSalesOrderOptions(
        res.map(c => ({
          value: c.id,
          label: c.order_ref,
        })),
      );
    });
  }, []);

  const selectSpecificContract = e => {
    if (e.value !== 0) {
      getSpecificContractById(e.value).then(r => {
        setSpecificContract(r);
        setSelectedSpecificContract(e);
        setSpecificContractOpenEnded(r?.order_end === null ? true : false);
      });
    } else {
      setSpecificContract({
        contract_ref: null,
        description: null,
        order_start: null,
        order_end: null,
        daily_rate: null,
        days_ordered: null,
        department: null,
        client_id: null,
        point_of_contact_id: null,
        invoicing_type: null,
        value: null,
      });
      setSpecificContractOpenEnded(false);

      setSelectedSpecificContract(e);
    }
  };

  const selectSalesOrder = e => {
    if (e.value !== 0) {
      getSalesOrderById(e.value).then(res => {
        setSalesOrder(res);
        setSalesOrderOpenEnded(res?.order_end === null ? true : false);
      });
    } else {
      setSalesOrder({
        order_ref: null,
        description: null,
        order_start: null,
        order_end: null,
        daily_rate: null,
        days_ordered: null,
        department: null,
        point_of_contact_id: null,
        client_id: null,
        invoicing_type: null,
        value: null,
      });
      setSalesOrderOpenEnded(false);
    }
    setSelectedSalesOrder(e);
  };

  let clientsListboxData = useMemo(() => {
    let array = [];
    if (clients.length) {
      clients.map(type => {
        array.push({
          value: type.id,
          label: type.name,
        });
      });
    }
    return array;
  }, [clients]);

  let contactsListboxData = useMemo(() => {
    let array = [];
    if (contacts.length) {
      contacts.map(type => {
        array.push({
          value: type.id,
          label: type.first_name + ' ' + type.last_name,
        });
      });
    }
    return array;
  }, [contacts]);

  const countriesListboxData = useMemo(() => {
    let array = [];
    if (countries?.length) {
      countries.forEach(c => {
        array.push({
          value: c.id,
          label: c.name,
        });
      });
    }
    return array;
  }, [countries]);

  const invoicingTypeListBoxData = Object.keys(contractInvoicingTypes.strings).map(key => ({
    value: Number(key),
    label: contractInvoicingTypes.strings[key],
  }));

  //FIXME: When checking for formErrors, we expect days_ordered to either have a value or to be null. However, as we see here, if the user deletes the value, it comes back as an empty string. Should we correct it in the handleChange functions or in the formErrors function?

  const handleSpecificContractChange = (e, label) => {
    //add logic to update value field if invoicing_type OR days_ordered OR daily_rate changes. Also, if type changes, the value should probably be reset
    let newValue;
    let newDailyRate;
    let newDaysOrdered;
    if (specificContract.invoicing_type === contractInvoicingTypes.numbers.timeAndMeans) {
      if (label === 'days_ordered') {
        newValue =
          e !== null && specificContract.daily_rate
            ? formatNumber(Number(specificContract.daily_rate) * Number(e?.target?.value))
            : null;
      }

      if (label === 'daily_rate') {
        newValue =
          e !== null && specificContract.days_ordered
            ? formatNumber(Number(specificContract.days_ordered) * Number(e?.target?.value))
            : null;
      }
    }

    if (label === 'invoicing_type' && e !== null) {
      let selectedTimeAndMeans = e === contractInvoicingTypes.numbers.timeAndMeans;
      newValue =
        selectedTimeAndMeans && specificContract.days_ordered && specificContract.daily_rate
          ? formatNumber(Number(specificContract.days_ordered) * Number(specificContract.daily_rate))
          : null;
      newDailyRate = selectedTimeAndMeans ? specificContract.daily_rate : null;
      newDaysOrdered = selectedTimeAndMeans ? specificContract.days_ordered : null;
    }

    setSpecificContract(prev => ({
      ...prev,
      [label]: e?.target ? e?.target?.value || null : e,
      ...(newValue !== undefined && { value: newValue }),
      ...(newDailyRate !== undefined && { daily_rate: newDailyRate }),
      ...(newDaysOrdered !== undefined && { days_ordered: newDaysOrdered }),
    }));
  };

  const handleSalesOrderChange = (e, label) => {
    //add logic to update value field if invoicing_type OR days_ordered OR daily_rate changes. Also, if type changes, the value should probably be reset
    let newValue;
    let newDailyRate;
    let newDaysOrdered;
    let newBillingUnits;
    if (salesOrder.invoicing_type === contractInvoicingTypes.numbers.timeAndMeans) {
      if (label === 'days_ordered') {
        newValue =
          e !== null && salesOrder.daily_rate ? formatNumber(Number(salesOrder.daily_rate) * Number(e?.target?.value)) : null;
      }

      if (label === 'daily_rate') {
        newValue =
          e !== null && salesOrder.days_ordered ? formatNumber(Number(salesOrder.days_ordered) * Number(e?.target?.value)) : null;
      }
    }

    if (label === 'invoicing_type' && e !== null) {
      newValue =
        e === contractInvoicingTypes.numbers.timeAndMeans && salesOrder.days_ordered && salesOrder.daily_rate
          ? formatNumber(Number(salesOrder.days_ordered) * Number(salesOrder.daily_rate))
          : null;
      newDailyRate = e === contractInvoicingTypes.numbers.timeAndMeans ? salesOrder.daily_rate : null;
      newDaysOrdered = e === contractInvoicingTypes.numbers.timeAndMeans ? salesOrder.days_ordered : null;
      newBillingUnits = e === contractInvoicingTypes.numbers.timeAndMeans ? billingUnits?.numbers?.days : null;
    }

    setSalesOrder(prev => ({
      ...prev,
      [label]: e?.target ? e?.target?.value || null : e,
      ...(newValue !== undefined && { value: newValue }),
      ...(newDailyRate !== undefined && { daily_rate: newDailyRate }),
      ...(newDaysOrdered !== undefined && { days_ordered: newDaysOrdered }),
      ...(newBillingUnits !== undefined && { billing_units: newBillingUnits }),
    }));
  };

  return (
    <TwoColumnForm
      label="Specific contract and sales order"
      description="Please select the specific contract and sales order applicable to this purchase order."
    >
      <DividerWithLabel label="Specific contract" />
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="w-full col-span-2">
          <ReactSelect
            label={'Contract'}
            options={specificContractOptions}
            value={selectedSpecificContract}
            isSearchable
            createOption
            onChange={e => selectSpecificContract(e)}
          />
        </div>

        {selectedSpecificContract.value === 0 ? (
          <>
            <InputSimple
              label={'Reference*'}
              onChange={handleSpecificContractChange}
              error={formErrors.find(e => e.field === 'specificContract-contract_ref')?.msg}
              dataLabel={'contract_ref'}
              value={specificContract.contract_ref}
            />
            <InputSimple
              label={'Description'}
              error={formErrors.find(e => e.field === 'specificContract-description')?.msg}
              dataLabel={'description'}
              onChange={handleSpecificContractChange}
              value={specificContract.description}
            />
            <InputDate
              label={'Start Date*'}
              error={formErrors.find(e => e.field === 'specificContract-order_start')?.msg}
              onChange={value => handleSpecificContractChange(value, 'order_start')}
              selected={specificContract.order_start && new Date(specificContract.order_start)}
            />
            <div className="flex">
              <InputDate
                disabled={specificContractOpenEnded}
                label={'End Date*'}
                error={formErrors.find(e => e.field === 'specificContract-order_end')?.msg}
                onChange={value => handleSpecificContractChange(value, 'order_end')}
                selected={specificContract.order_end && new Date(specificContract.order_end)}
              />
              <Checkbox
                className="ml-4"
                value={specificContractOpenEnded}
                onChange={e => setSpecificContractOpenEnded(e.target.checked)}
                title="Open Ended"
              />
            </div>
            <ReactSelect
              label="Invoicing type*"
              options={invoicingTypeListBoxData}
              placeholder="Select type"
              error={formErrors.find(e => e.field === 'specificContract-invoicing_type')?.msg}
              selectedOptionsIds={[specificContract.invoicing_type]}
              onChange={e => {
                handleSpecificContractChange(e.value, 'invoicing_type');
              }}
            />
            <InputSimple
              type="number"
              label={'Price (€/day)*'}
              error={formErrors.find(e => e.field === 'specificContract-daily_rate')?.msg}
              dataLabel={'daily_rate'}
              onChange={handleSpecificContractChange}
              value={specificContract.daily_rate}
              disabled={specificContract.invoicing_type === contractInvoicingTypes.numbers.fixedPrice}
            />
            <InputSimple
              type="number"
              label={'Days ordered*'}
              dataLabel={'days_ordered'}
              step=".5"
              error={formErrors.find(e => e.field === 'specificContract-days_ordered')?.msg}
              onChange={handleSpecificContractChange}
              value={specificContract.days_ordered}
              placeholder={specificContract.invoicing_type === contractInvoicingTypes.numbers.fixedPrice ? '' : 'FTE'}
              disabled={specificContract.invoicing_type === contractInvoicingTypes.numbers.fixedPrice}
            />
            <InputSimple
              type="number"
              label={specificContract.invoicing_type !== contractInvoicingTypes.numbers.fixedPrice ? 'Value (€)' : 'Value (€)*'}
              dataLabel="value"
              error={formErrors.find(e => e.field === 'specificContract-value')?.msg}
              onChange={handleSpecificContractChange}
              value={specificContract.value}
              disabled={specificContract.invoicing_type !== contractInvoicingTypes.numbers.fixedPrice}
            />
            <InputSimple
              label={'Department'}
              dataLabel={'department'}
              error={formErrors.find(e => e.field === 'specificContract-department')?.msg}
              onChange={handleSpecificContractChange}
              value={specificContract.department}
            />
            <div className="flex flex-col">
              <div className="flex items-end">
                <ReactSelect
                  isSearchable
                  label={'Client*'}
                  placeholder="Select client"
                  error={formErrors.find(e => e.field === 'specificContract-client_id')?.msg}
                  options={clientsListboxData}
                  selectedOptionsIds={[specificContract?.client_id]}
                  onChange={el => handleSpecificContractChange(el.value, 'client_id')}
                />
                <RefreshButton onClick={() => fetchAllClients()} />
              </div>
              <NewTabOpener link={'/admin-panel/admin/clients/create'} title="Add New Client" />
            </div>
            <div className="flex flex-col">
              <div className="flex items-end">
                <ReactSelect
                  isSearchable
                  label={'Country*'}
                  placeholder="Select country"
                  error={formErrors.find(e => e.field === 'specificContract-country_id')?.msg}
                  options={countriesListboxData}
                  selectedOptionsIds={[specificContract?.country_id]}
                  onChange={el => handleSpecificContractChange(el.value, 'country_id')}
                />
                <RefreshButton onClick={() => fetchAllCountries()} />
              </div>
              <NewTabOpener link={'/admin-panel/admin/countries/create'} title="Add New Country" />
            </div>
            <div className="flex flex-col">
              <div className="flex items-end">
                <ReactSelect
                  isSearchable
                  error={formErrors.find(e => e.field === 'specificContract-point_of_contact_id')?.msg}
                  label={'Point of contact'}
                  placeholder="Select point of contact"
                  options={contactsListboxData}
                  selectedOptionsIds={[specificContract?.point_of_contact_id]}
                  //When isClearable option is turned on, the element (el) returned is null so we cannot access value
                  onChange={el => handleSpecificContractChange(el ? el.value : null, 'point_of_contact_id')}
                  isClearable
                />
                <RefreshButton onClick={() => fetchAllContacts()} />
              </div>
              <NewTabOpener link={'/admin-panel/admin/points-of-contact/create'} title="Add New Point of contact" />
            </div>
          </>
        ) : (
          <>
            <SimpleEntry label={'Reference'} data={specificContract.contract_ref || '-'} style={`sm:col-span-1`} />
            <SimpleEntry label={'Description'} data={specificContract.description || '-'} style={`sm:col-span-1`} />
            <SimpleEntry label={'Start date'} data={date(specificContract.order_start || '-')} style={`sm:col-span-1`} />
            <SimpleEntry
              label={'End date'}
              data={(specificContract.order_end && date(specificContract.order_end)) || 'Open Ended'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={'Invoicing type'}
              data={contractInvoicingTypes.strings[specificContract.invoicing_type]}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={'Price (€/day)*'}
              data={specificContract.daily_rate ? formatNumber(specificContract.daily_rate, true) : '-'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={'Days ordered'}
              data={specificContract.days_ordered ? formatNumber(specificContract.days_ordered, true) : 'FTE'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={`${specificContract.days_ordered ? 'Remaining days' : 'Consumed days'}`}
              data={`${
                specificContract.days_ordered
                  ? formatNumber(
                      new Number(specificContract.days_ordered).valueOf() - new Number(specificContract.days_consumed).valueOf(),
                      true,
                    )
                  : formatNumber(specificContract.days_consumed, true)
              }`}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={'Value (€)'}
              data={specificContract?.value ? formatNumber(specificContract.value, true) : '-'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry label={'Department'} data={specificContract.department || '-'} style={`sm:col-span-1`} />
            <SimpleEntry
              label={'Client'}
              error={formErrors.find(e => e.field === 'specificContract-client_id')?.msg}
              data={(specificContract.client_id && clients.find(c => c.id === specificContract.client_id).name) || '-'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={'Point of Contact'}
              data={
                (specificContract.point_of_contact_id &&
                  contacts.find(c => c.id === specificContract.point_of_contact_id).name) ||
                '-'
              }
              style={`sm:col-span-1`}
            />
            <SimpleEntry label={'Created'} data={date(specificContract.created_at) || '-'} style={`sm:col-span-1`} />
            <SimpleEntry label={'Last updated'} data={date(specificContract.updated_at) || '-'} style={`sm:col-span-1`} />
          </>
        )}
      </dl>
      <DividerWithLabel label="Sales order" />
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="w-full col-span-2">
          <ReactSelect
            label={'Order'}
            options={salesOrderOptions}
            value={selectedSalesOrder}
            isSearchable
            createOption
            onChange={e => selectSalesOrder(e)}
          />
        </div>
        {selectedSalesOrder.value === 0 ? (
          <>
            <InputSimple
              error={formErrors.find(e => e.field === 'salesOrder-order_ref')?.msg}
              label={'Reference*'}
              onChange={handleSalesOrderChange}
              dataLabel={'order_ref'}
              value={salesOrder.order_ref}
            />
            <InputSimple
              label={'Description'}
              dataLabel={'description'}
              error={formErrors.find(e => e.field === 'salesOrder-description')?.msg}
              onChange={handleSalesOrderChange}
              value={salesOrder.description}
            />
            <InputDate
              label={'Start Date*'}
              error={formErrors.find(e => e.field === 'salesOrder-order_start')?.msg}
              onChange={value => handleSalesOrderChange(value, 'order_start')}
              selected={salesOrder.order_start && new Date(salesOrder.order_start)}
            />
            <div className="flex">
              <InputDate
                disabled={salesOrderOpenEnded}
                label={'End Date*'}
                error={formErrors.find(e => e.field === 'salesOrder-order_end')?.msg}
                onChange={value => handleSalesOrderChange(value, 'order_end')}
                selected={salesOrder.order_end && new Date(salesOrder.order_end)}
              />
              <Checkbox
                className="ml-4"
                value={salesOrderOpenEnded}
                onChange={e => setSalesOrderOpenEnded(e.target.checked)}
                title="Open Ended"
              />
            </div>
            <ReactSelect
              label="Invoicing type*"
              options={invoicingTypeListBoxData}
              placeholder="Select type"
              error={formErrors.find(e => e.field === 'salesOrder-invoicing_type')?.msg}
              selectedOptionsIds={[salesOrder.invoicing_type]}
              onChange={e => handleSalesOrderChange(e.value, 'invoicing_type')}
            />
            <InputSimple
              type="number"
              label={'Price (€/day)*'}
              error={formErrors.find(e => e.field === 'salesOrder-daily_rate')?.msg}
              dataLabel={'daily_rate'}
              onChange={handleSalesOrderChange}
              value={salesOrder.daily_rate}
              disabled={salesOrder.invoicing_type === contractInvoicingTypes.numbers.fixedPrice}
            />

            <ReactSelect
              label="Billing units"
              error={formErrors.find(e => e.field === 'salesOrder-billing_units')?.msg}
              options={Object.keys(billingUnits.strings).map(key => ({
                value: Number(key),
                label: billingUnits.strings[key],
              }))}
              selectedOptionsIds={[salesOrder?.billing_units]}
              onChange={e => handleSalesOrderChange(e.value, 'billing_units')}
              disabled={salesOrder?.invoicing_type === contractInvoicingTypes.numbers.fixedPrice}
            />

            <InputSimple
              type="number"
              label={'Days ordered*'}
              step=".5"
              error={formErrors.find(e => e.field === 'salesOrder-days_ordered')?.msg}
              dataLabel={'days_ordered'}
              onChange={handleSalesOrderChange}
              value={salesOrder.days_ordered ? formatNumber(salesOrder.days_ordered, true) : null}
              placeholder={salesOrder.invoicing_type === contractInvoicingTypes.numbers.fixedPrice ? '' : 'FTE'}
              disabled={salesOrder.invoicing_type === contractInvoicingTypes.numbers.fixedPrice}
            />
            <InputSimple
              type="number"
              label={salesOrder.invoicing_type !== contractInvoicingTypes.numbers.fixedPrice ? 'Value (€)' : 'Value (€)*'}
              dataLabel="value"
              error={formErrors.find(e => e.field === 'salesOrder-value')?.msg}
              onChange={handleSalesOrderChange}
              value={salesOrder.value}
              disabled={salesOrder.invoicing_type !== contractInvoicingTypes.numbers.fixedPrice}
            />
            <InputSimple
              label={'Department'}
              error={formErrors.find(e => e.field === 'salesOrder-department')?.msg}
              dataLabel={'department'}
              onChange={handleSalesOrderChange}
              value={salesOrder.department}
            />
            <div className="flex flex-col">
              <div className="flex items-end">
                <ReactSelect
                  isSearchable
                  label={'Client*'}
                  placeholder="Select client"
                  error={formErrors.find(e => e.field === 'salesOrder-client_id')?.msg}
                  options={clientsListboxData}
                  selectedOptionsIds={[salesOrder?.client_id]}
                  onChange={el => handleSalesOrderChange(el.value, 'client_id')}
                />
                <RefreshButton onClick={() => fetchAllClients()} />
              </div>
              <NewTabOpener link={'/admin-panel/admin/clients/create'} title="Add New Client" />
            </div>
            <div className="flex flex-col">
              <div className="flex items-end">
                <ReactSelect
                  isSearchable
                  label={'Point of contact'}
                  error={formErrors.find(e => e.field === 'salesOrder-point_of_contact_id')?.msg}
                  placeholder="Select point of contact"
                  options={contactsListboxData}
                  selectedOptionsIds={[salesOrder?.point_of_contact_id]}
                  //When isClearable option is turned on, the element (el) returned is null so we cannot access value
                  onChange={el => handleSalesOrderChange(el ? el.value : null, 'point_of_contact_id')}
                  isClearable
                />
                <RefreshButton onClick={() => fetchAllContacts()} />
              </div>
              <NewTabOpener link={'/admin-panel/admin/points-of-contact/create'} title="Add New Point of contact" />
            </div>
          </>
        ) : (
          <>
            <SimpleEntry label={'Reference'} data={salesOrder.order_ref || '-'} style={`sm:col-span-1`} />
            <SimpleEntry label={'Description'} data={salesOrder.description || '-'} style={`sm:col-span-1`} />
            <SimpleEntry label={'Start date'} data={date(salesOrder.order_start || '-')} style={`sm:col-span-1`} />
            <SimpleEntry
              label={'End date'}
              data={(salesOrder.order_end && date(salesOrder.order_end)) || 'Open Ended'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={'Invoicing type'}
              data={contractInvoicingTypes.strings[specificContract.invoicing_type]}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={'Price (€/day)'}
              data={salesOrder.daily_rate ? formatNumber(salesOrder.daily_rate, true) : '-'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={'Days ordered'}
              data={salesOrder.days_ordered ? formatNumber(salesOrder.days_ordered) : 'FTE'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={`${salesOrder.days_ordered ? 'Remaining days' : 'Consumed days'}`}
              data={`${
                salesOrder.days_ordered
                  ? formatNumber(
                      new Number(salesOrder.days_ordered).valueOf() - new Number(salesOrder.days_consumed).valueOf(),
                      true,
                    )
                  : formatNumber(salesOrder.days_consumed, true)
              }`}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={'Value (€)'}
              data={salesOrder?.value ? formatNumber(salesOrder.value, true) : '-'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry label={'Department'} data={salesOrder.department || '-'} style={`sm:col-span-1`} />
            <SimpleEntry
              label={'Client'}
              data={(salesOrder.client_id && clients.find(c => c.id === salesOrder.client_id).name) || '-'}
              error={formErrors.find(e => e.field === 'salesOrder-client_id')?.msg}
              style={`sm:col-span-1`}
            />
            <SimpleEntry
              label={'Point of Contact'}
              data={(salesOrder.point_of_oontact_id && contacts.find(c => c.id === salesOrder.point_of_oontact_id).name) || '-'}
              style={`sm:col-span-1`}
            />
            <SimpleEntry label={'Created'} data={date(salesOrder.created_at) || '-'} style={`sm:col-span-1`} />
            <SimpleEntry label={'Last updated'} data={date(salesOrder.updated_at) || '-'} style={`sm:col-span-1`} />
          </>
        )}
      </dl>
    </TwoColumnForm>
  );
}

export default AddSalesOrderAndSpecificContract;
