import HrManager from '../HrManager';
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import Table from '../../../../containers/ServerSideTable';
import {
  LeaveRequestStatusLabels,
  LeaveRequestType,
  LeaveRequestTypeLabel,
  LeaveRequestStatus,
} from '../../../../helpers/enum/leaveRequest';
import dayjs from 'dayjs';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
import NumberRangeColumnFilter from '../../../../components/TableItems/NumberRangeColumnFilter';
import { ExternalLinkIcon, PencilIcon } from '@heroicons/react/outline';
import useApi from '../../../../hooks/useApi';
import { americanDate } from '../../../../helpers/date';
import AdminSidebar from '../../AdminSidebar';

const LeaveRequestManager = () => {
  const {
    leaveRequests: { getAllLeaveRequestsPaginated, getFilterOptions },
  } = useApi();

  const history = useHistory();
  const {
    location: { state },
  } = useHistory();

  const [leaveRequests, setLeaveRequests] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});

  const fetchData = async (page = 0, filters = [], sortByArray = [], globalFilter, downloadCsv, source) => {
    const id = filters.find(filter => filter.id === 'id')?.value;
    const type = filters.find(filter => filter.id === 'request_type')?.value;
    const startDate = filters.find(filter => filter.id === 'start_date')?.value?.map(d => americanDate(d));
    const endDate = filters.find(filter => filter.id === 'end_date')?.value?.map(d => americanDate(d));
    const requestedDays = filters.find(filter => filter.id === 'deducted_leave_days')?.value;
    const users = filters.find(filter => filter.id === 'user_id')?.value;
    const status = filters.find(filter => filter.id === 'status')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;

    console.log(status);
    if (downloadCsv) {
      let csvData = [];
      await getAllLeaveRequestsPaginated(
        page + 1,
        id,
        type,
        startDate,
        endDate,
        requestedDays,
        users,
        status,
        sortBy,
        globalFilter,
        downloadCsv,
      ).then(res => {
        res.forEach(leaveRequest => {
          csvData.push({
            id: leaveRequest.id,
            request_type: LeaveRequestTypeLabel[leaveRequest.request_type],
            start_date: dayjs(leaveRequest.start_date).format('DD/MM/YYYY'),
            end_date: dayjs(leaveRequest.end_date).format('DD/MM/YYYY'),
            deducted_leave_days: leaveRequest.request_type == LeaveRequestType.paidLeave ? leaveRequest.deducted_leave_days : 0,
            user_id: `${leaveRequest.user.first_names} ${leaveRequest.user.last_names}`,
            status: LeaveRequestStatusLabels[leaveRequest.status],
            userId: leaveRequest.user.id,
          });
        });
      });
      return csvData;
    }

    getAllLeaveRequestsPaginated(
      page + 1,
      id,
      type,
      startDate,
      endDate,
      requestedDays,
      users,
      status,
      sortBy,
      globalFilter,
      null,
      source,
    ).then(d => {
      setLeaveRequests(d);
    });
  };

  useEffect(() => {
    //fetch filter options for users w/ timesheets + max and min period
    getFilterOptions().then(d => setFilterOptions(d));
  }, []);

  const dataTable = useMemo(() => {
    let data = [];
    if (leaveRequests && leaveRequests?.rows?.length) {
      leaveRequests.rows.forEach(leaveRequest =>
        data.push({
          id: leaveRequest.id,
          request_type: LeaveRequestTypeLabel[leaveRequest.request_type],
          start_date: dayjs(leaveRequest.start_date).format('DD/MM/YYYY'),
          end_date: dayjs(leaveRequest.end_date).format('DD/MM/YYYY'),
          deducted_leave_days: leaveRequest.request_type == LeaveRequestType.paidLeave ? leaveRequest.deducted_leave_days : 0,
          user_id: `${leaveRequest.user.first_names} ${leaveRequest.user.last_names}`,
          status: LeaveRequestStatusLabels[leaveRequest.status],
          userId: leaveRequest.user.id,
        }),
      );
    }
    return data;
  }, [leaveRequests, filterOptions]);

  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Type',
      accessor: 'request_type',
      Filter: SelectColumnFilter,
      filter: 'includes',
      filterOptions: Object.values(LeaveRequestType).map(val => ({
        label: LeaveRequestTypeLabel[val],
        value: val,
      })),
    },
    {
      Header: 'Start',
      accessor: 'start_date',
      Filter: DateRangeColumnFilter,
      filter: 'date',
      filterOptions: filterOptions?.startDate,
    },
    {
      Header: 'End',
      accessor: 'end_date',
      Filter: DateRangeColumnFilter,
      filter: 'date',
      filterOptions: filterOptions?.endDate,
    },
    {
      Header: 'Requested days',
      accessor: 'deducted_leave_days',
      Filter: NumberRangeColumnFilter,
      filter: 'between',
      Cell: ({ value }) => {
        return !value ? '-' : value;
      },
      filterOptions: filterOptions?.requestedDays,
    },
    {
      Header: 'Requester',
      accessor: 'user_id',
      Filter: SelectColumnFilter,
      filter: 'includes',
      filterOptions: filterOptions?.users?.length
        ? filterOptions.users.map(o => ({
            label: o.first_names + ' ' + o.last_names,
            value: o.id,
          }))
        : null,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'includes',
      filterOptions: Object.values(LeaveRequestStatus)
        .filter(val => ![LeaveRequestStatus.pending, LeaveRequestStatus.deleted].includes(val))
        .map(val => {
          if ([LeaveRequestStatus.pending, LeaveRequestStatus.deleted].includes(val)) return null;
          return {
            label: LeaveRequestStatusLabels[val],
            value: val,
          };
        }),
    },
  ]);

  let renderRowMenu = row => {
    const leaveRequest = leaveRequests.rows.find(leave => leave.id == row.original.id);
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/hr/leave-requests/${leaveRequest.user_id}/${row.original.id}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/hr/leave-requests/${leaveRequest.user_id}/${row.original.id}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
    ];
  };

  const rowOnClick = row => {
    const leaveRequest = leaveRequests?.rows?.find(leave => leave.id == row.values.id);
    history.push(`/admin-panel/hr/leave-requests/${leaveRequest.user_id}/${row.values.id}`);
  };

  const addButton = {
    link: '/admin-panel/hr/leave-requests/create',
  };

  const pages = [
    { name: 'HR Manager', href: '/admin-panel/hr/user-directory', current: false },
    { name: 'Manage Leave Requests', href: '/admin-panel/hr/leave-requests', current: false },
  ];

  return (
    <AdminSidebar noPadding pages={pages}>
      <Table
        addButton={addButton}
        columns={columns}
        data={dataTable}
        customContextMenu
        contextMenuOptions={renderRowMenu}
        rowOnClick={rowOnClick}
        initialFilters={state?.filters}
        pageCount={leaveRequests?.totalPages}
        fetchData={fetchData}
        totalItems={leaveRequests?.totalItems}
        tableName="leaveRequests"
      />
    </AdminSidebar>
  );
};

export default LeaveRequestManager;
