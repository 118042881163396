import React from 'react';
import {
  ClipboardCheckIcon,
  ClipboardListIcon,
  DownloadIcon,
  EyeIcon,
  ReceiptTaxIcon,
  TrashIcon,
  DocumentTextIcon,
  ExternalLinkIcon,
} from '@heroicons/react/outline';
import ClipLoader from 'react-spinners/ClipLoader';

const AttachmentCard = ({
  document,
  enableDownload,
  isPreviewable,
  preparingPreview,
  onPreviewHandler,
  downloading,
  onDownloadHandler,
  enableRemove,
  deleting,
  onRemoveHandler,
  fileIcon,
  enableLink,
  onLinkHandler,
}) => {
  let icon;

  const docName = document?.resource ? document?.resource.split('/')[document?.resource.split('/').length - 1] : document?.name;

  switch (fileIcon) {
    case 'draftTimesheet':
      icon = <ClipboardListIcon className="w-5 h-5 text-gray-500" />;
      break;
    case 'signedTimesheet':
      icon = <ClipboardCheckIcon className="w-5 h-5 text-gray-500" />;
      break;
    case 'invoice':
      icon = <ReceiptTaxIcon className="w-5 h-5 text-gray-500" />;
      break;
    default:
      icon = fileIcon || <DocumentTextIcon className="w-5 h-5 text-gray-500" />;
      break;
  }
  return (
    <div className="flex justify-between w-full overflow-hidden">
      <div className="flex-1 flex items-center truncate">
        <div className="flex-none">{icon}</div>
        <div className="ml-2 flex gap-x-2">
          <span className="truncate text-ellipsis">{docName}</span>
        </div>
      </div>
      <div className="ml-4 flex-shrink-0 flex space-x-1">
        {enableDownload && (
          <>
            {isPreviewable(docName) &&
              (preparingPreview === document.id ? (
                <>
                  <ClipLoader className="mr-2" size={18} color={'#4f46e5'} />
                  <span className="text-gray-300" aria-hidden="true">
                    |
                  </span>
                </>
              ) : (
                <>
                  <button
                    onClick={() => onPreviewHandler(document.id)}
                    type="button"
                    disabled={downloading}
                    className="bg-white rounded-md font-medium text-thaleria-blue-400 hover:text-thaleria-blue-800"
                  >
                    <EyeIcon className="w-4 h-4 text-thaleria-blue-400 hover:text-thaleria-blue-800" />
                  </button>
                  <span className="text-gray-300" aria-hidden="true">
                    |
                  </span>
                </>
              ))}

            {downloading === document.id ? (
              <ClipLoader className="mr-2" size={18} color={'#4f46e5'} />
            ) : (
              <button
                onClick={onDownloadHandler.bind(this, document.id)}
                type="button"
                className="bg-white rounded-md font-medium text-thaleria-blue-400 hover:text-thaleria-blue-800"
              >
                <DownloadIcon className="w-4 h-4 text-thaleria-blue-400 hover:text-thaleria-blue-800" />
              </button>
            )}
          </>
        )}

        {enableRemove && enableDownload && (
          <span className="text-gray-300" aria-hidden="true">
            |
          </span>
        )}
        {enableRemove &&
          //if document.id and deleting are both undefined, which now happens in create AP invoice, the loading icon is constantly on
          //adding document.id !== undefined prevents that error and should allow other components to keep using this feature
          (document.id !== undefined && deleting === document.id ? (
            <ClipLoader className="mr-2" size={18} color={'#ef4444'} />
          ) : (
            <button
              type="button"
              onClick={onRemoveHandler.bind(this, document.id)}
              className="bg-white rounded-md font-medium text-red-600 hover:text-red-500"
            >
              <TrashIcon className="h-4 w-4 text-red-600 hover:text-red-500" />
            </button>
          ))}
        {enableLink && (
          <>
            <span className="text-gray-300" aria-hidden="true">
              |
            </span>
            <button
              type="button"
              onClick={onLinkHandler.bind(this, document.accounts_payable_id)}
              className="bg-white rounded-md font-medium text-thaleria-blue-400 hover:text-thaleria-blue-800"
            >
              <ExternalLinkIcon className="h-4 w-4 text-thaleria-blue-400 hover:text-thaleria-blue-800" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AttachmentCard;
