import React, { useEffect, useMemo, useState, useRef } from 'react';
import Table from '../../../../containers/ServerSideTable';
import Finance from '../Finance';
import useApi from '../../../../hooks/useApi';
import SelectColumnFilter from '../../../../components/TableItems/SelectColumnFilter';
import NumberRangeColumnFilter from '../../../../components/TableItems/NumberRangeColumnFilter';
import { format } from 'date-fns';
import { showSuccessNotification } from '../../../../store/app/actions';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import DateRangeColumnFilter from '../../../../components/TableItems/DateRangeColumnFilter';
import { americanDate } from '../../../../helpers/date';
import formatCurrency from '../../../../utils/formatCurrency';
import { ExclamationIcon, ExternalLinkIcon, EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import AccountsReceivablePDFPreview from '../../../../containers/Finance/AccountsReceivable/AccountsReceivablePDFPreview';
import { useHistory } from 'react-router-dom';
import PayInvoice from '../../../../components/Modals/PayInvoice';
import paidStatus from '../../../../helpers/enum/paidStatus';
import AdminSidebar from '../../AdminSidebar';
import SimpleAlert from '../../../../components/Modals/SimpleAlert';
import { ClipLoader } from 'react-spinners';
import OverdueKPIModal from '../../../../containers/Finance/AccountsReceivable/OverdueKPIModal';

function AccountsReceivables() {
  const {
    location: { state },
  } = useHistory();
  const [accountsReceivablesData, setAccountsReceivablesData] = useState();
  const [filterOptions, setFilterOptions] = useState();
  const [showPayModal, setShowPayModal] = useState(false);
  const [invoiceToPay, setInvoiceToPay] = useState();
  const [showBulkDownloadAlert, setShowBulkDownloadAlert] = useState(false);
  const [bulkDownloading, setBulkDownloading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showKPI, setShowKPI] = useState(false);
  const {
    accountsReceivables: {
      getAccountsReceivables,
      getAccountsReceivableFilterOptions,
      deleteAccountsReceivable,
      updatePaidOrSentStatus,
      getAccountsReceivableByInvoiceNb,
      bulkDownloadAR,
    },
  } = useApi();

  useEffect(() => {
    getAccountsReceivableFilterOptions().then(r => {
      setFilterOptions(r);
    });
  }, []);

  const history = useHistory();

  const myRefs = useRef({});

  const today = new Date();

  const basicInvoiceDataObject = {
    invoice_number: null,
    issue_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())),
    due_date: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() + 30)),
    client_id: null,
    revenue: null,
    vat: null,
    revenue_and_vat: null,
    sent: false,
    paid: null,
    bank_account_id: null,
    metadata: {
      to: {
        name: '',
        address: '',
        postal_code: '',
        city: '',
        country: '',
        vat_number: '',
      },
      from: {
        name: 'Thaleria S.A.',
        address: 'Avenue des Arts 56',
        postal_code: '1000',
        city: 'Brussels',
        country: 'Belgium',
        vat_number: 'BE 0704.867.821',
      },
      subject: 'Thaleria services',
      header_comment: `Please find below the details of your invoice containing the references of the framework contract and/or agreement, specific contract and/or purchase order, period covered, price per unit, quantity and unit of measurement.`,
      footer_comment: `Please pay the amount of {{total_incl_vat}} to the account {{receivables_iban}} by {{due_date}}.`,
      footer_data: {
        name: 'Thaleria S.A.',
        email: 'finance@thaleria.com',
        phone: '+32 474 95 74 11',
        vat_number: 'BE 0704.867.821',
        iban: '',
        bic_swift: '',
      },
      showReferences: {
        frameworkContract: true,
        partnerContract: true,
        salesOrder: true,
        specificContract: true,
      },
    },
  };

  const [showInvoicePreview, setShowInvoicePreview] = useState(false);
  const [invoiceData, setInvoiceData] = useState(basicInvoiceDataObject);
  const [lineItems, setLineItems] = useState([]);
  const [filters, setFilters] = useState({});

  const invoicePreviewHandler = (event, id) => {
    if (!id) return;
    getAccountsReceivableByInvoiceNb(id).then(res => {
      res.issue_date = new Date(res.issue_date);
      res.due_date = new Date(res.due_date);
      res.metadata = JSON.parse(res.metadata);

      setInvoiceData(res || basicInvoiceDataObject);
      setLineItems(
        res.lineItems.map(item => {
          item.metadata = JSON.parse(item.metadata);
          return item;
        }),
      );
      setShowInvoicePreview(true);
    });
    event.stopPropagation();
  };

  const fetchAccountsReceivables = async (
    page = 0,
    filters = [],
    sortByArray = [],
    globalFilter,
    downloadToCsv,
    source,
    bulkDownload,
    fileNameStructure,
  ) => {
    const invoiceNumbers = filters.find(filter => filter.id === 'invoiceNumber')?.value;
    const clients = filters.find(filter => filter.id === 'client')?.value;
    const revenue = filters.find(filter => filter.id === 'revenue')?.value;
    const vat = filters.find(filter => filter.id === 'vat')?.value;
    const grandTotal = filters.find(filter => filter.id === 'grandTotal')?.value;
    const sent = filters.find(filter => filter.id === 'sent')?.value;
    const paid = filters.find(filter => filter.id === 'paid')?.value;
    const paidAmount = filters.find(filter => filter.id === 'paidAmount')?.value;
    let issueDate = filters.find(filter => filter.id === 'issueDate')?.value;
    let dueDate = filters.find(filter => filter.id === 'dueDate')?.value;
    const createdBy = filters.find(filter => filter.id === 'createdBy')?.value;
    const overdue = filters.find(filter => filter.id === 'overdue')?.value;
    const sortBy = sortByArray.length ? `${sortByArray[0].id.toString()},${sortByArray[0].desc.toString()}` : undefined;
    let csvData = [];
    if (issueDate !== undefined) {
      issueDate = issueDate.map(d => {
        if (d !== undefined) return americanDate(d);
      });
    }
    if (dueDate !== undefined) {
      dueDate = dueDate.map(d => {
        if (d !== undefined) return americanDate(d);
      });
    }
    setFilters({
      invoiceNumbers,
      clients,
      revenue,
      vat,
      grandTotal,
      sent,
      paidAmount,
      issueDate,
      createdBy,
      overdue,
      sortBy,
      globalFilter,
    });

    if (downloadToCsv) {
      await getAccountsReceivables(
        page + 1,
        invoiceNumbers,
        clients,
        revenue,
        vat,
        grandTotal,
        sent,
        paid,
        createdBy,
        issueDate,
        dueDate,
        overdue,
        paidAmount,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
      ).then(r => {
        r.forEach(e => {
          csvData.push({
            id: e?.id,
            client: e?.client?.name,
            vat: e?.vat || '-',
            revenue: e?.revenue,
            grandTotal: e?.revenue_and_vat || '-',
            sent: { id: e?.id, sent: e?.sent },
            paid: { id: e?.id, paid: e?.paid },
            createdBy: e?.createdBy.full_name,
            issueDate: e?.issue_date ? format(new Date(e?.issue_date), 'dd/MM/yyyy') : '-',
            dueDate: e?.due_date ? format(new Date(e?.due_date), 'dd/MM/yyyy') : '-',
            invoiceNumber: e?.invoice_number || '-',
          });
        });
      });
      return csvData;
    }

    if (bulkDownload) {
      setBulkDownloading(true);
      await getAccountsReceivables(
        page + 1,
        invoiceNumbers,
        clients,
        revenue,
        vat,
        grandTotal,
        sent,
        paid,
        createdBy,
        issueDate,
        dueDate,
        overdue,
        paidAmount,
        sortBy,
        globalFilter,
        downloadToCsv,
        source,
        bulkDownload,
      ).then(res => {
        bulkDownloadAR(res.map(ar => ar.id).join(','), fileNameStructure, source).then(res => {
          setShowBulkDownloadAlert(false);
          setBulkDownloading(false);
        });
      });
      return;
    }
    getAccountsReceivables(
      page + 1,
      invoiceNumbers,
      clients,
      revenue,
      vat,
      grandTotal,
      sent,
      paid,
      createdBy,
      issueDate,
      dueDate,
      overdue,
      paidAmount,
      sortBy,
      globalFilter,
      downloadToCsv,
      source,
    ).then(r => {
      setAccountsReceivablesData(r);
    });
  };

  const tableData = useMemo(() => {
    let array = [];
    if (accountsReceivablesData?.rows?.length) {
      accountsReceivablesData.rows.forEach((e, index) => {
        let revenue =
          e?.adjusted_revenue && Number(e?.adjusted_revenue) !== Number(e?.revenue)
            ? formatCurrency(e.adjusted_revenue) + '*'
            : formatCurrency(e.revenue);
        let vat =
          e?.adjusted_vat && Number(e?.adjusted_vat) !== Number(e?.vat)
            ? formatCurrency(e.adjusted_vat) + '*'
            : formatCurrency(e.vat);
        let revenue_and_vat =
          e?.adjusted_revenue_and_vat && Number(e?.adjusted_revenue_and_vat) !== Number(e?.revenue_and_vat)
            ? formatCurrency(e.adjusted_revenue_and_vat) + '*'
            : formatCurrency(e.revenue_and_vat);
        array.push({
          id: e?.id,
          client: e?.client?.name,
          vat: vat || '-',
          revenue: revenue || '-',
          grandTotal: revenue_and_vat || '-',
          sent: { id: e?.id, sent: e?.sent, paid: e.paid },
          paid: {
            id: e?.id,
            paid: e?.paid,
            sent: e?.sent,
            paidAmount: e?.paid_amount,
            paymentDate: e?.payment_date,
            revenueAndVat: e?.adjusted_revenue_and_vat,
          },
          createdBy: e?.createdBy.full_name,
          issueDate: e?.issue_date ? format(new Date(e?.issue_date), 'dd/MM/yyyy') : '-',
          dueDate: e?.due_date ? format(new Date(e?.due_date), 'dd/MM/yyyy') : '-',
          invoiceNumber: { number: e?.invoice_number || '-', id: e?.id },
          overdue: e?.paid === paidStatus.numbers.paid ? null : e?.overdue,
          paidAmount: formatCurrency(e?.paid_amount) || '-',
          paymentDate: e?.payment_date ? format(new Date(e?.issue_date), 'dd/MM/yyyy') : '-',
        });
      });
    }
    return array;
  }, [accountsReceivablesData]);

  const updateInvoiceSent = (id, invoiceSent) => {
    updatePaidOrSentStatus(id, { sent: invoiceSent }).then(res => {
      if (!accountsReceivablesData.rows.length) return;
      const newRows = accountsReceivablesData.rows.map(obj => {
        let newObj = obj;
        if (obj.id === id) {
          newObj.sent = invoiceSent;
        }
        return newObj;
      });

      setAccountsReceivablesData(prev => ({
        ...prev,
        rows: newRows,
      }));
    });
  };

  const updateInvoicePaid = (id, invoicePaid, date, paidAmount) => {
    let dataToUpdate = {
      paid: invoicePaid,
      payment_date: invoicePaid === paidStatus.numbers.unpaid ? null : date,
      paid_amount: invoicePaid === paidStatus.numbers.unpaid ? null : paidAmount,
    };

    updatePaidOrSentStatus(id, dataToUpdate).then(res => {
      if (!accountsReceivablesData.rows.length) return;
      const newRows = accountsReceivablesData.rows.map(obj => {
        let newObj = obj;
        if (obj.id === id) {
          newObj.paid = res.paid;
          newObj.paid_amount = res.paid_amount;
          newObj.overdue = res.overdue;
        }
        return newObj;
      });

      setAccountsReceivablesData(prev => ({
        ...prev,
        rows: newRows,
      }));
      setShowPayModal(false);
      if (invoicePaid === paidStatus.numbers.partiallyPaid) myRefs.current[id].indeterminate = true;
      else myRefs.current[id].indeterminate = false;
    });
  };

  const deleteRowHandler = id => {
    setIsDeleting(true);
    deleteAccountsReceivable(id)
      .then(res => {
        showSuccessNotification('Successfully deleted accounts receivable entry');
        if (accountsReceivablesData) {
          const newRows = accountsReceivablesData.rows.filter(obj => obj.id !== id);
          setAccountsReceivablesData(prev => ({
            ...prev,
            rows: newRows,
          }));
        }
        setIsDeleting(false);
        setShowDeleteAlert(false);
      })
      .catch(err => {
        setIsDeleting(false);
      });
  };

  const onPaidClickHandler = invoice => {
    setInvoiceToPay({
      id: invoice.id,
      paid: invoice.paid,
      paidAmount: invoice.paidAmount,
      paymentDate: invoice.paymentDate,
      revenueAndVat: invoice.revenueAndVat,
    });
    setShowPayModal(true);
  };

  const overdueIconColor = value => {
    if (value >= 1 && value <= 30) {
      return 'text-yellow-200'; // You can replace 'red' with the desired color or icon for this range
    } else if (value > 30 && value <= 60) {
      return 'text-orange-300'; // Replace 'yellow' with the desired color or icon for this range
    } else if (value > 60 && value <= 90) {
      return 'text-orange-500'; // Replace 'orange' with the desired color or icon for this range
    } else if (value > 90) {
      return 'text-red-500'; // Replace 'green' with the desired color or icon for this range
    }
  };

  const overdueFilterOptions = [
    {
      value: 0,
      label: 'No',
    },
    {
      value: 1,
      label: 'Yes',
    },
    {
      value: 2,
      label: '<30 days',
    },
    {
      value: 3,
      label: '30-60 days',
    },
    {
      value: 4,
      label: '60-90 days',
    },
    {
      value: 5,
      label: '>90days',
    },
  ];

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Invoice ID',
        accessor: 'invoiceNumber',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions
          ? filterOptions?.invoiceNumberOptions?.map(el => ({
              value: el.invoice_number,
              label: el.invoice_number,
            }))
          : [],
        Cell: ({ value }) => {
          return (
            <div className="flex space-x-4 translate-y-0.5 mr-2 mb-1">
              <span>{value.number}</span>
              <EyeIcon className="w-5 h-5 cursor-pointer" onClick={event => invoicePreviewHandler(event, value.number)} />
            </div>
          );
        },
      },
      {
        Header: 'Issue date',
        accessor: 'issueDate',
        Filter: DateRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Due date',
        accessor: 'dueDate',
        Filter: DateRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Overdue',
        accessor: 'overdue',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: overdueFilterOptions,
        Cell: ({ value }) => {
          return (
            <span className={` flex gap-x-2 items-center`}>
              {value ? <ExclamationIcon className={`$w-6 h-6 ${overdueIconColor(value)}`} /> : ''}
              {value !== null ? value + ' days' : '-'}
            </span>
          );
        },
      },
      {
        Header: 'Invoice sent',
        accessor: 'sent',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: [
          { value: 1, label: 'Yes' },
          { value: 0, label: 'No' },
        ],
        Cell: ({ value }) => {
          return (
            <span className="flex justify-center divide-x-2 items-center">
              <Checkbox
                value={value?.sent}
                className="pb-1 pl-2"
                disabled={value.paid}
                horizontal
                onChange={() => updateInvoiceSent(value?.id, !value?.sent)}
              />
            </span>
          );
        },
      },
      {
        Header: 'Invoice paid',
        accessor: 'paid',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: [
          { value: 2, label: 'In full' },
          { value: 1, label: 'Partially paid' },
          { value: 0, label: 'Unpaid' },
        ],
        Cell: ({ value }) => {
          return (
            <span className="flex justify-center divide-x-2 items-center">
              <input
                id={value.id}
                ref={el => {
                  if (value.paid === paidStatus.numbers.partiallyPaid && el?.indeterminate === false) {
                    el.indeterminate = true;
                  }
                  return (myRefs.current[value.id] = el);
                }}
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                disabled={!value.sent}
                checked={value?.paid}
                onChange={() => onPaidClickHandler(value)}
                onClick={e => e.stopPropagation()}
                className={`focus:ring-thaleria-orange-500 h-4 w-4 border-gray-300 rounded mb-1 ${
                  !value.sent ? 'bg-gray-200 cursor-not-allowed text-gray-300' : 'text-thaleria-orange-600'
                }`}
              />
            </span>
          );
        },
      },
      {
        Header: 'Paid amount',
        accessor: 'paidAmount',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Client',
        accessor: 'client',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions
          ? filterOptions?.clientIdOptions?.map(el => ({
              value: el.client_id,
              label: el.client_name,
            }))
          : [],
      },
      {
        Header: 'Revenue',
        accessor: 'revenue',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'VAT owed',
        accessor: 'vat',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Grand Total',
        accessor: 'grandTotal',
        Filter: NumberRangeColumnFilter,
        filter: 'between',
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Filter: SelectColumnFilter,
        filter: 'includes',
        filterOptions: filterOptions
          ? filterOptions?.userOptions?.map(el => ({
              value: el.user_id,
              label: el.full_name,
            }))
          : [],
      },
    ];
    return cols;
  }, [accountsReceivablesData, filterOptions]);

  const pages = [
    { name: 'Finance Manager', href: '/admin-panel/finance/invoicing-items', current: false },
    { name: 'Accounts Receivable', href: '/admin-panel/finance/accounts-receivables', current: false },
  ];

  const renderRowMenu = row => {
    return [
      [
        {
          text: 'View/edit details',
          onClick: () => history.push(`/admin-panel/finance/accounts-receivable/${row.original.invoiceNumber.number}`),
          show: true,
          icon: PencilIcon,
        },
        {
          text: 'View/edit details in new tab',
          onClick: () => window.open(`/admin-panel/finance/accounts-receivable/${row.original.invoiceNumber.number}`),
          show: true,
          icon: ExternalLinkIcon,
        },
      ],
      [
        {
          text: 'Delete row',
          onClick: () => setShowDeleteAlert(row.original.id),
          show: true,
          icon: TrashIcon,
        },
      ],
    ];
  };

  const addButton = {
    link: '/admin-panel/finance/accounts-receivable/create',
  };

  const rowOnClick = row => {
    history.push(`/admin-panel/finance/accounts-receivable/${row.original.invoiceNumber.number}`);
  };

  return (
    <AdminSidebar noPadding pages={pages}>
      <SimpleAlert
        errorTitle={'Delete Invoice?'}
        errorMsg={'Deleting will completely remove this Invoice from the application. Are you sure you want to proceed?'}
        onAcceptText={isDeleting ? <ClipLoader className="mr-2 w-20" color={'#FFFF'} size={17} /> : 'Confirm'}
        onAcceptClick={() => deleteRowHandler(showDeleteAlert)}
        onDeclineText="Cancel"
        show={showDeleteAlert ? true : false}
        hide={() => setShowDeleteAlert(false)}
      />
      <AccountsReceivablePDFPreview
        setShowPreview={setShowInvoicePreview}
        showPreview={showInvoicePreview}
        invoiceData={invoiceData}
        lineItems={lineItems}
      />
      <PayInvoice
        show={showPayModal}
        setShow={setShowPayModal}
        id={invoiceToPay?.id}
        _paid={invoiceToPay?.paid}
        _paidAmount={invoiceToPay?.paidAmount}
        _paymentDate={invoiceToPay?.paymentDate}
        onConfirm={updateInvoicePaid}
        totalAmount={invoiceToPay?.revenueAndVat}
      />
      <OverdueKPIModal show={showKPI} setShow={setShowKPI} filters={filters} />
      <Table
        columns={columns}
        data={tableData}
        fetchData={fetchAccountsReceivables}
        contextMenuOptions={renderRowMenu}
        customContextMenu
        pageCount={accountsReceivablesData?.totalPages}
        tableName="accountsReceivable"
        totalItems={accountsReceivablesData?.totalItems}
        rowOnClick={rowOnClick}
        addButton={addButton}
        bulkDownloadButton={true}
        bulkDownloading={bulkDownloading}
        initialFilters={state?.filters}
        setBulkDownloading={setBulkDownloading}
        showBulkDownloadAlert={showBulkDownloadAlert}
        setShowBulkDownloadAlert={setShowBulkDownloadAlert}
        kpiButton={true}
        setShowKPI={setShowKPI}
      />
    </AdminSidebar>
  );
}

export default AccountsReceivables;
