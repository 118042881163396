import { PlusCircleIcon } from '@heroicons/react/outline';

const FinanceFileNameMacros = ({ addMacroToTemplate }) => {
  return (
    <div className="grid grid-cols-2 w-full gap-6">
      {/* Macros Buttons */}
      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[Number]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">Invoice Number*</span>
          <span className="text-xs">[Number]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>

      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[PartnerRef]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">End-client FWC</span>
          <span className="text-xs">[FWCRef]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>
      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[PartnerRef]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">Partner FWC</span>
          <span className="text-xs">[PartnerRef]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>

      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[SCRef]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">Specific Contract Ref</span>
          <span className="text-xs">[SCRef]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>

      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[SORef]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">Sales Order Ref</span>
          <span className="text-xs">[SORef]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>

      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[UserName]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">User name</span>
          <span className="text-xs">[UserName]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>
      <div
        className="col-span-1 flex justify-between items-center rounded-md border group border-gray-300 hover:bg-gray-50 cursor-pointer p-2"
        onClick={() => addMacroToTemplate('[Date]')}
      >
        <div className="flex flex-col">
          <span className="text-sm">Date</span>
          <span className="text-xs">[Date]</span>
        </div>
        <PlusCircleIcon className="w-6 h-6 text-thaleria-orange-400 cursor-pointer group-hover:text-thaleria-orange-600" />
      </div>
    </div>
  );
};

export default FinanceFileNameMacros;
