import React, { useState, useEffect, useRef, useMemo } from 'react';
import InputSimple from '../../../components/Inputs/InputSimple';
import ReactSelect from '../../../components/Inputs/ReactSelect';
import { ReceiptTaxIcon } from '@heroicons/react/outline';
import formatNumber from '../../../utils/formatNumber';
import useApi from '../../../hooks/useApi';
import InputRadio from '../../../components/Inputs/InputRadio';
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary';
import Error from '../../../components/Error/Error';
import InputDate from '../../../components/Inputs/InputDate/InputDate';
import ButtonWhite from '../../../components/Buttons/ButtonWhite';
import AttachmentCard from '../../../components/Attachments/AttachmentCard';
import { useHistory, useParams } from 'react-router-dom';
import PreviewBox from '../../PDFViewer/PreviewBox';
import { downloadBase64File } from '../../../helpers';
import AccountsPayableLineItemEdits from '../../../components/Tables/AccountsPayableLineItemEdits';
import EditLineItemDecimalPlaces from '../../../components/Modals/EditLineItemDecimalPlaces';
import SimpleEntry from '../../../components/DescriptionEntries/SimpleEntry';
import { lineItemTypesV2 } from '../../../helpers/enum/lineItemTypes';

const UserInvoiceEdit = ({
  invoice,
  setInvoice,
  file,
  activeStaffOrders,
  formErrors,
  edit,
  setEdit,
  handleEditClick,
  handleSaveClick,
  setShowDeleteAlert,
}) => {
  console.log(invoice);
  const checkAllRef = useRef();
  const inputInvoice = useRef();
  const [iban, setIban] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [staffContract, setStaffContract] = useState(null);
  const [customLine, setCustomLine] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [preparingPreview, setPreparingPreview] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [staffContractOptions, setStaffContractOptions] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showEditDecimalPlaces, setShowEditDecimalPlaces] = useState(false);
  const [totals, setTotals] = useState({
    total: 0,
    vat: 0,
    total_and_vat: 0,
  });

  const { id } = useParams();
  const history = useHistory();

  const {
    accountsPayable: { downloadAPFile },
  } = useApi();

  useEffect(() => {
    let total = 0;
    let vat = 0;
    console.log('calculating totals', invoice.lineItems);

    invoice?.lineItems?.forEach(line => {
      if (line.checked) {
        total += Number(line.total);
        if (line?.vat) vat += Number(line.vat);
      }
    });

    setTotals({
      total,
      vat,
      total_and_vat: total + vat,
    });
  }, [invoice?.lineItems]);

  const handleCheckLine = index => {
    const updatedLines = invoice.lineItems.map((line, i) => ({
      ...line,
      checked: i === index ? !line.checked : line.checked,
    }));
    setInvoice(prev => ({
      ...prev,
      lineItems: updatedLines,
    }));

    const allChecked = updatedLines.every(line => line.checked);
    const someChecked = updatedLines.some(line => line.checked);

    setCheckAll(allChecked);
    if (checkAllRef.current) {
      checkAllRef.current.indeterminate = !allChecked && someChecked;
    }
  };

  useEffect(() => {
    if (customLine !== null) {
      let total = null;
      let vat = null;
      let total_and_vat = null;

      if (customLine?.units != null && customLine?.price != null) {
        total = customLine?.units * customLine?.price;
        vat = customLine?.vat_rate != null ? total * (customLine?.vat_rate / 100) : 0;
        total_and_vat = total + vat;
      }

      setCustomLine(prevState => ({
        ...prevState,
        total,
        vat,
        total_and_vat,
      }));
    }
  }, [customLine?.units, customLine?.price, customLine?.vat_rate]);

  const saveCustomLine = () => {
    setInvoice(prev => ({ ...prev, lineItems: [...prev.lineItems, customLine] }));
    setCustomLine(null);
  };

  const handleCheckAll = () => {
    const newCheckAll = !checkAll;
    const updatedLines = invoice.lineItems.map(line => ({
      ...line,
      checked: newCheckAll,
    }));
    setInvoice(prev => ({
      ...prev,
      lineItems: updatedLines,
    }));
    setCheckAll(newCheckAll);
  };

  const onRemoveHandler = () => {
    inputInvoice.current.value = '';
    // setfile(null);
  };

  const defaultFileTypes = `.csv, image/png,
  image/jpg,
  image/jpeg,
  image/gif,
  image/x-png,
  application/pdf,
  application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.openxmlformats-officedocument.wordprocessingml.template,
  application/vnd.ms-word.document.macroEnabled.12,
  application/vnd.ms-word.template.macroEnabled.12,
  application/vnd.ms-excel,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  application/vnd.openxmlformats-officedocument.spreadsheetml.template,
  application/vnd.ms-excel.sheet.macroEnabled.12,
  application/vnd.ms-excel.template.macroEnabled.12,
  application/vnd.ms-excel.sheet.binary.macroEnabled.12,
  application/vnd.ms-excel.addin.macroEnabled.12,
  application/vnd.ms-powerpoint,
  application/vnd.openxmlformats-officedocument.presentationml.template,
  application/vnd.openxmlformats-officedocument.presentationml.slideshow,
  application/vnd.openxmlformats-officedocument.presentationml.presentation,
  application/vnd.ms-powerpoint.presentation.macroEnabled.12,
  application/vnd.ms-powerpoint.template.macroEnabled.12,
  application/vnd.ms-powerpoint.slideshow.macroEnabled.12`;

  const onChangeInvoice = e => {
    e.stopPropagation();
    e.preventDefault();
    let file = e.target.files[0];
    const maxFileSizeInMb = 10;
    if (maxFileSizeInMb) {
      if (file?.size > maxFileSizeInMb * 1000000) {
        alert(`Maximum file size is ${maxFileSizeInMb}mb`);
        return null;
      }
    }
    setUploading(true);
  };

  const isPreviewable = fileName => {
    let bool = false;
    const extension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (extension === 'pdf' || extension === 'jpg' || extension === 'png' || extension === 'jpeg') bool = true;
    return bool;
  };

  useEffect(() => {
    //iban options vary depending on selected lines for this invoice
    let _staffContractOptions = [];
    let uniqueLabels = new Set();

    invoice?.lineItems?.forEach(li => {
      console.log('li', li);
      if (li?.checked && li?.staffOrder) {
        let label = li?.staffOrder?.staffContract?.contract_ref;
        let option = {
          value: li.staffOrder?.staffContract?.id,
          label: label,
          iban: `${li.staffOrder?.staffContract?.iban} - ${li.staffOrder?.staffContract?.bic_swift}`,
          company_name: li.staffOrder?.staffContract?.company_name,
        };
        //remove duplicate staffContracts
        if (!uniqueLabels.has(label)) {
          uniqueLabels.add(label);
          _staffContractOptions.push(option);
        }
      }
    });
    setStaffContractOptions(_staffContractOptions);
    if (_staffContractOptions?.length === 1) {
      setStaffContract(_staffContractOptions[0]);
      setCompanyName(_staffContractOptions[0]?.company_name);
      setIban(_staffContractOptions[0]?.iban);
    } else if (!_staffContractOptions?.find(i => i?.label?.split(' - ')[0] === invoice.iban)) {
      setIban(null);
      setStaffContract(null);
      setCompanyName(null);
      setInvoice(prev => ({ ...prev, iban: null, bic_swift: null, company_name: null, staff_contract_id: null }));
    } else if (!iban) {
      //this is only to set iban on first render
      const selectedOption = _staffContractOptions?.find(i => i?.iban?.split(' - ')[0] === invoice.iban);
      const iban = selectedOption?.iban?.split(' - ')[0];
      const bic_swift = selectedOption?.iban?.split(' - ')[1];
      const companyName = selectedOption?.companyName;
      setIban(selectedOption?.iban);
      setCompanyName(companyName);
      setStaffContract(selectedOption);
      setInvoice(prev => ({
        ...prev,
        iban: iban,
        bic_swift: bic_swift,
        company_name: companyName,
        staff_contract_id: selectedOption.value,
      }));
    }
  }, [invoice?.lineItems]);

  const previewHandler = () => {
    downloadAPFile(id)
      .then(response => {
        setPreparingPreview(false);
        setShowPreview({
          url: response.data,
          show: true,
          fileType: file.resource.split('.')[file.resource.split('.').length - 1],
          title: file.resource.split('.')[file.resource.split('.').length - 2].replace('/', ''),
        });
      })
      .catch(() => {
        setPreparingPreview(false);
      });
  };

  const downloadHandler = () => {
    setDownloading(true);
    const fileName = file.resource.split('/')[file.resource.split('/').length - 1];
    downloadAPFile(id)
      .then(response => {
        downloadBase64File(response.data, fileName);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  const staffContractChangeHandler = e => {
    const ibanAndSwift = e?.iban;
    const companyName = e?.company_name;
    setStaffContract(e);
    setInvoice(prev => ({
      ...prev,
      iban: ibanAndSwift.split(' - ')[0],
      bic_swift: ibanAndSwift.split(' - ')[1],
      company_name: companyName,
      staff_contract_id: e.value,
    }));
    setIban(ibanAndSwift);
    setCompanyName(companyName);
  };

  const saveDecimalPlaces = decimalPlaces => {
    const newLineItems = invoice.lineItems.map((li, i) => {
      if (i === showEditDecimalPlaces) {
        const newUnits = formatNumber(li?.metadata?.hours / 8, false, decimalPlaces);
        let isNegativeAmount = li.type === lineItemTypesV2.accountsPayable.numbers.projectAllocationCreditNoteAdjustment;
        const total = newUnits * Number(li.price) * (isNegativeAmount ? -1 : 1);
        const vat = newUnits * Number(li.price) * (Number(li?.vat_rate) / 100);
        const totalAndVat = total + vat;
        return {
          ...li,
          units: newUnits,
          total: total,
          vat: vat,
          total_and_vat: totalAndVat,
          metadata: {
            ...li.metadata,
            new_decimal_places: decimalPlaces,
          },
        };
      }
      return li;
    });
    setInvoice(prev => ({
      ...prev,
      lineItems: newLineItems,
    }));
    setShowEditDecimalPlaces(false);
  };

  return (
    <div className="bg-white h-full p-4 flex flex-col gap-y-0 justify-between">
      <EditLineItemDecimalPlaces
        show={showEditDecimalPlaces !== false && !isNaN(showEditDecimalPlaces) ? true : false}
        setShow={setShowEditDecimalPlaces}
        lineItem={invoice.lineItems[showEditDecimalPlaces]}
        onConfirm={value => saveDecimalPlaces(value)}
      />
      <div>
        <h3>Edit user's invoice</h3>
        <input
          type="file"
          id="invoice"
          accept={defaultFileTypes}
          ref={inputInvoice}
          onChange={onChangeInvoice}
          style={{ display: 'none' }}
        />
        <PreviewBox
          filePath={showPreview.url}
          fileType={showPreview.fileType}
          showPreview={showPreview.show}
          handleHide={() => setShowPreview(false)}
          title={showPreview.title}
        />
        <dl className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="col-span-1">
            <InputSimple
              label="Invoice number*"
              value={invoice.invoice_number}
              onChange={e => setInvoice(prev => ({ ...prev, invoice_number: e.target.value }))}
              error={formErrors.find(e => e.field === 'invoiceNumber')?.msg}
            />
          </div>
          <div className="col-span-1">
            <div className="flex items-center mb-1 gap-x-2">
              <span className="text-sm ">Invoice*</span>
              {formErrors.find(e => e.field === 'invoiceFile') && (
                <Error message={formErrors.find(e => e.field === 'invoiceFile').msg} />
              )}
            </div>
            <ul className="divide-y divide-thaleria-blue-200/75 border border-gray-200 rounded-md">
              <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
                {file ? (
                  <AttachmentCard
                    document={file}
                    enableDownload
                    isPreviewable={isPreviewable}
                    preparingPreview={preparingPreview}
                    enableRemove={false}
                    deleting={deleting}
                    onRemoveHandler={onRemoveHandler}
                    fileIcon={'invoice'}
                    onPreviewHandler={previewHandler}
                    downloading={downloading}
                    onDownloadHandler={downloadHandler}
                  />
                ) : (
                  <div className="flex justify-start">
                    <ReceiptTaxIcon className="w-5 h-5 text-gray-500" />
                    <span className="ml-2 text-gray-600">No invoice found</span>
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className="col-span-1">
            <ReactSelect
              label="Staff contract*"
              options={staffContractOptions}
              onChange={e => staffContractChangeHandler(e)}
              selectedOptions={[staffContract]}
              error={formErrors.find(e => e.field === 'staffContract')?.msg}
            />
          </div>
          <div className="col-span-1">
            <SimpleEntry label="IBAN - BIC/SWIFT" data={iban} />
          </div>
          <InputDate
            label="Due date*"
            onChange={value => setInvoice(prev => ({ ...prev, due_date: value }))}
            selected={invoice.due_date}
          />
          <div className="col-span-1">
            <SimpleEntry label="Company name" data={companyName} />
          </div>
        </dl>
        <div className="mt-8 mb-4 flex flex-col">
          <AccountsPayableLineItemEdits
            availableLines={invoice?.lineItems}
            customLine={customLine}
            setCustomLine={setCustomLine}
            formErrors={formErrors}
            handleCheckAll={handleCheckAll}
            handleCheckLine={handleCheckLine}
            saveCustomLine={saveCustomLine}
            activeStaffOrders={activeStaffOrders}
            checkAll={checkAll}
            setShowEditDecimalPlaces={setShowEditDecimalPlaces}
            totals={totals}
          />
          <div>
            <span className="mt-4 py-4 flex justify-between items-center ">
              <InputRadio
                label="Does the amount shown here match the one on the user's invoice?*"
                options={['Yes', 'No']}
                selectedValue={invoice.amount_match === true ? 'Yes' : 'No'}
                onChange={e => setInvoice(prev => ({ ...prev, amount_match: e.target.value === 'Yes' ? true : false }))}
                error={formErrors.find(e => e.field === 'isAMatch')?.msg}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <div className="p-4 flex flex-row-reverse justify-between md:col-span-2">
          <div className="text-right">
            {!edit && (
              <div>
                <ButtonWhite style="mr-2" onClick={() => history.push('/admin-panel/finance/accounts-payable')} text="Back" />
                <ButtonPrimary onClick={handleEditClick} text="Edit" disable={invoice?.approved} />
              </div>
            )}
            {edit && (
              <div>
                <ButtonWhite style="mr-2" onClick={() => setEdit(false)} text="Cancel changes" />
                <ButtonPrimary onClick={handleSaveClick} text="Save changes" />
              </div>
            )}
          </div>
          <div className="text-left">
            {!edit && <ButtonPrimary canDelete color="red" style="mr-2" onClick={() => setShowDeleteAlert(true)} text="Delete" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInvoiceEdit;
